import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Button,
  Toolbar,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  Alert,
  Zoom,
  Fab,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  TextField,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { logout } from "../services/AuthService";
import { logoutSuccess } from "../reducers/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { LOGIN_ROUTE, PROFILE_ROUTE } from "../helper/Constants";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import * as supportService from "../services/SupportService";
import { LoadingButton } from "@mui/lab";

const pages = [];
const useStyles = makeStyles((theme) => ({
  logo: {
    height: "6em",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      height: "5em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "4.5em",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //   ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    minHeight: `calc(100vh - 100px)`,
  },
}));

export default function ResponsiveLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const { appState, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(false);
  const [loadingSupport, setLoadingSupport] = useState();
  const [supportMessageError, setSupportMessageError] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fanoutFabStyle = {
    position: "fixed",
    bottom: 16,
    right: 16,
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    if (appState.isLoggedIn) {
      setAnchorElUser(event.currentTarget);
    } else {
      navigate(LOGIN_ROUTE);
    }
  };

  //   const handleActivites = () => {
  //       handleCloseUserMenu()
  //   }

  const handleProfileNavigation = () => {
    navigate(PROFILE_ROUTE);
    handleCloseUserMenu();
  };

  const handleNewEvent = () => {
    handleCloseNavMenu();
  };

  const handleLogout = () => {
    logout().then((res) => {
      dispatch(logoutSuccess());
      handleCloseUserMenu();
      navigate("/");
    });
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="static" id="header">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                mr: 1,
                mt: 1,
                mb: 1,
                cursor: "pointer",
              }}
              component="img"
              onClick={() => navigate("/")}
              className={classes.logo}
              alt="BusinessCaseLab"
              src="/resources/logo.svg"
            />
            <Typography
              variant="subtitle2"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mt: 2,
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Business Case Lab
            </Typography>

            {/* change it to  display: { xs: 'flex', md: 'none' } to be displayed */}
            <Box sx={{ flexGrow: 1, display: { xs: ["flex", "none"] } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleNewEvent}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                mr: 1,
                cursor: "pointer",
              }}
              component="img"
              onClick={() => navigate("/")}
              className={classes.logo}
              alt="BusinessCaseLab"
              src="/resources/logo.svg"
            />
            <Typography
              variant="caption"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mt: 2,
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Business Case Lab
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                ml: 5,
                mr: 5,
                mt: 0,
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <Button
                  variant="contained"
                  key={page}
                  onClick={handleNewEvent}
                  sx={{ color: "orange", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="user info">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  {appState.isLoggedIn ? (
                    <>
                      <Typography
                        sx={{
                          mr: 1,
                          fontSize: {
                            lg: "0.8rem",
                            md: "0.7rem",
                            sm: "0.6rem",
                            xs: "0.5rem",
                          },
                        }}
                      >
                        {appState.userInfo?.firstName}{" "}
                        {matchesSM ? appState.userInfo?.lastName : null}
                      </Typography>
                      <Avatar src={appState.userInfo?.photoUrl} />
                    </>
                  ) : (
                    <Typography
                      textAlign="center"
                      sx={{
                        typography: {
                          xs: "caption",
                          md: "subtitle2",
                        },
                        fontSize: {
                          xs: "0.5rem",
                          sm: "0.6rem",
                          md: "0.875rem",
                        },
                      }}
                    >
                      SIGN IN
                    </Typography>
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* <MenuItem onClick={handleActivites}>
                        <Typography textAlign="center">My Activities</Typography>
                    </MenuItem> */}
                <MenuItem onClick={handleProfileNavigation}>
                  <Typography textAlign="center">My Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        {appState.isLoggedIn && !appState.userInfo?.hasPromptPermission && (
          <Alert
            severity="warning"
            action={
              <Button color="inherit" size="small">
                Contact us
              </Button>
            }
          >
            No more credits avaiable to create new case. Please contact us to
            get unblocked!
          </Alert>
        )}
      </AppBar>

      {/* main content */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
        {/* Move it to separate compenent */}
        {appState.isLoggedIn && (
          <React.Fragment>
            <Zoom
              key="fanoutFab"
              in={true}
              timeout={transitionDuration}
              style={{
                transitionDelay: `2000ms`,
              }}
              unmountOnExit
            >
              <Fab
                variant="extended"
                sx={fanoutFabStyle}
                color="success"
                onClick={handleClickOpen}
              >
                <SupportAgentIcon sx={{ mr: 1 }} />
                {matchesSM ? "Support" : null}
              </Fab>
            </Zoom>

            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                component: "form",
                onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const message = formJson.message?.trim();
                  if (!message) {
                    setSupportMessageError("Message cannot be blank.");
                    return;
                  }
                  setLoadingSupport(true);
                  supportService
                    .send(message)
                    .then((res) => console.log("Done"))
                    .catch((e) => {
                      console.error(`Error: ${e.message}`);
                    })
                    .finally(() => {
                      setLoadingSupport(false);
                      handleClose();
                    });
                },
              }}
            >
              <DialogTitle id="responsive-dialog-title">
                {"Help is Just a Click Away!"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Let’s make this easy! Whether you’ve run into a bug, can’t
                  find what you’re looking for, or just need a little advice,
                  our support team is here to help
                </DialogContentText>
              </DialogContent>
              <TextField
                sx={{ ml: 3, mr: 3 }}
                autoFocus
                multiline
                minRows={5}
                required
                margin="dense"
                id="message"
                name="message"
                label="Your Message"
                variant="outlined"
                error={Boolean(supportMessageError)}
                helperText={supportMessageError}
              />
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loadingSupport} type="submit">
                  Send
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
        {/* Move it to separate compenent */}
      </main>
    </>
  );
}
