import React from "react";
import { Typography, Card, CardContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function NewCard({ onclick, title = "New Item", icon }) {
  return (
    <Card
      id="new-card"
      elevation={5}
      sx={{
        m: 0.5,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent onClick={onclick} sx={{ m: 3 }}>
        {icon || <AddIcon style={{ fontSize: 48, color: "#3f51b5" }} />}
        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}
