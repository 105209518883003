import * as React from "react";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

/* Info icon in the top-right corner with a tooltip */
const InfoTooltip = ({ title }) => {
  return (
    <Tooltip
      title={
        <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>{title}</span>
      }
      arrow
    >
      <IconButton
        size="small"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          transform: "translate(10%, -25%)", // Adjust the positioning as needed
        }}
      >
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
