import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  Collapse,
  Slider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import {
  MuiInput,
  MuiDatePicker,
  MuiButton,
  IOSSwitch,
} from "../../../../components";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useForm, Form } from "../../../../components/mui/useForm";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../../../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../../../components/PageHeader";
import Promotion from "./Promotion";
import * as assignmentService from "../../services/AssignmentService";
import * as institutionService from "../../services/InstitutionService";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import AutoCompleteAsync from "../../../../components/AutoCompleteAsync";
import { format, isValid, isAfter, parse } from "date-fns";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { capitalize } from "../../../../helper/TextHelper";

export default function New() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [newPrice, setNewPrice] = useState();
  const [addingNewQuestion, setAddingNewQuestion] = useState();
  const [newQuestionError, setNewQuestionError] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [loading, setLoading] = useState(true);
  const [promotionPercentage, setpromotionPercentage] = useState(20);
  const [submitting, setSubmitting] = useState();
  const [questions, setQuestions] = useState([]);
  const [gradingTypes, setGradingsType] = useState([]);
  const [gradingTypesLoading, setGradingsTypeLoading] = useState();
  const [questionsError, setQuestionsError] = useState();
  const casestudy = state.casestudy;
  const institutionId = state.institutionId;
  const assignment = state.assignment;
  const assignmentId = state.assignment?.id;
  const caseStudyId = casestudy.id;
  const caseStudyPrice = casestudy.price;
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isInEditMode = !!assignmentId;

  const openNewDialog = () => {
    setOpen(true);
  };

  const closeNewDialog = () => {
    setOpen(false);
  };

  const applyPromotion = (amount) => {
    const discount = (caseStudyPrice * amount) / 100;
    const newPrice = (caseStudyPrice - discount).toFixed(2);
    setNewPrice(newPrice);
    return newPrice;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues)
      temp.title = fieldValues.title ? "" : "This field is required.";
    if ("dueDate" in fieldValues)
      temp.dueDate = fieldValues.dueDate
        ? isValid(fieldValues.dueDate)
          ? ""
          : "Date is not valid."
        : "This field is required.";
    if ("type" in fieldValues) {
      temp.type = fieldValues.type ? "" : "This field is required.";
    }
    if (fieldValues.evaluation?.feedback?.enabled) {
      temp.feedbackDueDate = values.evaluation.feedback.dueDate
        ? isValid(values.evaluation.feedback?.dueDate)
          ? values.dueDate &&
            isAfter(values.evaluation.feedback.dueDate, values.dueDate)
            ? ""
            : "Must be after project due date."
          : "Date is not valid."
        : "This field is required when feedback enabled.";
    } else {
      delete temp.feedbackDueDate;
    }

    if (fieldValues.promotion?.enabled && newPrice < 1 && newPrice > 0) {
      temp.promotion = "The price must not be between zero and one.";
    } else {
      delete temp.promotion;
    }

    if (fieldValues.evaluation?.enabled && !fieldValues.evaluation.grading) {
      temp.grading = "This field is required for evaluation.";
    } else {
      delete temp.grading;
    }

    if (!isInEditMode && fieldValues.evaluation?.enabled) {
      if (questions.length === 0) {
        setQuestionsError("Can not be empty with enabed evaluation.");
      } else {
        temp.questions = {};
        questions.forEach((question) => {
          if (question.criteria?.length === 0) {
            temp.questions[question.id] =
              "At least one criteria is required for evaluation.";
          } else {
            if (hasDuplicateTitles(question.criteria)) {
              temp.questions[question.id] =
                "Criteria titles can not be the same.";
            }

            const totalWeight = question.criteria.reduce((sum, criterion) => {
              return sum + parseFloat(criterion.weight || 0);
            }, 0);

            // If total weight is not equal to 100, store the error message for this question
            if (totalWeight !== 100) {
              temp.questions[
                question.id
              ] = `The total weight must equal 100%. Current total is ${totalWeight}%.`;
            }
          }
        });

        // If no errors were added to temp.questions, set it to an empty string
        if (Object.keys(temp.questions).length === 0) {
          temp.questions = "";
        }
      }
    } else {
      delete temp.questions;
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const hasDuplicateTitles = (criteria) => {
    const titleSet = new Set();
    for (const criterion of criteria) {
      if (titleSet.has(criterion.title)) {
        return true; // Duplicate title found
      }
      titleSet.add(criterion.title);
    }
    return false; // No duplicates found
  };

  const initValues = () => {
    if (isInEditMode) {
      return {
        title: assignment.title,
        type: assignment.typeId,
        dueDate: parse(assignment.dueDate, "yyyy-MM-dd", new Date()),
        discount: assignment.discount ? assignment.discount : 0,
        promotion: assignment.discount
          ? { enabled: true, expanded: true }
          : { enabled: false, expanded: false },
        evaluation: assignment.grading
          ? {
              enabled: true,
              expanded: true,
              feedback: assignment.feedbackDueDate
                ? {
                    enabled: true,
                    expanded: true,
                    threshold: assignment.feedbackThreshold,
                    dueDate: parse(
                      assignment.feedbackDueDate,
                      "yyyy-MM-dd",
                      new Date()
                    ),
                  }
                : {
                    enabled: false,
                    expanded: false,
                    threshold: 80,
                  },
              grading: assignment.grading,
            }
          : {
              enabled: false,
              expanded: false,
              feedback: {
                enabled: false,
                expanded: false,
                threshold: 80,
              },
              grading: assignment.grading,
            },
      };
    } else {
      return {
        title: "",
        type: "",
        dueDate: "",
        discount: 0,
        promotion: { enabled: false, expanded: false },
        evaluation: {
          enabled: false,
          expanded: false,
          feedback: {
            enabled: false,
            expanded: false,
            threshold: 80,
          },
        },
      };
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initValues());

  useEffect(() => {
    if (gradingTypes.length === 0) {
      setGradingsTypeLoading(true);
      institutionService
        .gradingLevels()
        .then((response) => {
          setGradingsType(response.data);
          const balanced = response.data.find((item) => item.value === 5);
          if (!isInEditMode) {
            setValues((prev) => ({
              ...prev,
              evaluation: {
                ...prev.evaluation,
                grading: balanced,
              },
            }));
          }
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setGradingsTypeLoading(false));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    institutionService
      .promotion(institutionId)
      .then((response) => {
        setpromotionPercentage(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    assignmentService
      .getAllQuestions(casestudy.id)
      .then((response) => {
        setQuestions(response.data.questions);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isInEditMode && validate()) {
      setSubmitting(true);
      const request = {
        assignmentId: assignmentId,
        title: values.title,
        type: values.type,
        dueDate: format(values.dueDate, "yyyy-MM-dd"),
        discount: values.promotion?.enabled ? values.discount : null,
        evaluation: values.evaluation?.enabled
          ? {
              gradingId: values.evaluation.grading.id,
              feedback: values.evaluation.feedback.enabled
                ? {
                    threshold: values.evaluation.feedback.threshold,
                    dueDate: format(
                      values.evaluation.feedback.dueDate,
                      "yyyy-MM-dd"
                    ),
                  }
                : null,
            }
          : null,
      };
      assignmentService
        .update(request)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Updated Successfully",
            type: "success",
          });
          setTimeout(() => {
            // Add your logic for the transition
            resetForm();
            navigate(-1, { replace: true });
          }, 2000);
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not update an assignment. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setSubmitting(false));
    } else if (validate()) {
      setSubmitting(true);
      const request = {
        caseStudyId: casestudy.id,
        title: values.title,
        type: values.type,
        dueDate: format(values.dueDate, "yyyy-MM-dd"),
        discount: values.promotion?.enabled ? values.discount : null,
        evaluation: values.evaluation?.enabled
          ? {
              questions: questions,
              gradingId: values.evaluation.grading.id,
              feedback: values.evaluation.feedback.enabled
                ? {
                    threshold: values.evaluation.feedback.threshold,
                    dueDate: format(
                      values.evaluation.feedback.dueDate,
                      "yyyy-MM-dd"
                    ),
                  }
                : null,
            }
          : null,
      };
      assignmentService
        .create(request)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
          setTimeout(() => {
            // Add your logic for the transition
            resetForm();
            navigate(-1, { replace: true });
          }, 2000);
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not create an assignment. Please try again later.",
            type: "error",
          });
        })
        .finally(() => setSubmitting(false));
    }
  };

  const addCriteria = (questionId) => {
    const newCriteria = {
      id: Date.now(), // Unique ID
      title: "",
      requirement: "",
    };

    const updatedQuestions = questions.map((question) =>
      question.id === questionId
        ? {
            ...question,
            criteria: Array.isArray(question.criteria)
              ? [...question.criteria, newCriteria]
              : [newCriteria], // If criteria is undefined or not an array, initialize it as an array
          }
        : question
    );

    setQuestions(updatedQuestions);
  };

  const handleCriteriaDelete = (questionId, criteriaId) => {
    const updatedQuestions = questions.map((question) =>
      question.id === questionId
        ? {
            ...question,
            criteria: question.criteria.filter(
              (criterion) => criteriaId !== criterion.id
            ),
          }
        : question
    );

    setQuestions(updatedQuestions);
  };

  const handleCriteriaWeight = (questionId, criterionId, e) => {
    const { value } = e.target;

    // Update the weight of the specific criterion for the matching question
    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          criteria: question.criteria.map((criterion) => {
            if (criterion.id === criterionId) {
              return {
                ...criterion,
                // weight will be null if equals to zero on backend
                weight: value === 0 ? "0" : value, // Update the weight with the new value
              };
            }
            return criterion;
          }),
        };
      }
      return question;
    });

    setQuestions(updatedQuestions); // Update the state with the new questions
  };

  const handleCriteriaTitle = (questionId, criterionId, e) => {
    const { value } = e.target;

    // Update the weight of the specific criterion for the matching question
    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          criteria: question.criteria.map((criterion) => {
            if (criterion.id === criterionId) {
              return {
                ...criterion,
                title: value,
              };
            }
            return criterion;
          }),
        };
      }
      return question;
    });

    setQuestions(updatedQuestions); // Update the state with the new questions
  };

  const onTypeChange = (event, type) => {
    type = type ?? ""; // Set type to an empty string if it's null or undefined
    setValues({ ...values, type: type.id || "", typeTitle: type.title }); // Handle case where type may not have an id
    validate({ type: type.id || "" });
  };

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    if ("evaluation-switch" === name) {
      if (!checked) {
        setValues((prev) => ({
          ...prev,
          evaluation: {
            enabled: false,
            expanded: false,
            feedback: {
              enabled: false,
              expanded: false,
              threshold: 80,
            },
          },
        }));
      } else {
        setValues((prev) => ({
          ...prev,
          evaluation: {
            ...prev.evaluation,
            enabled: checked,
            expanded: checked,
          },
        }));
      }
    } else if ("promotion-switch" === name) {
      setValues((prev) => ({
        ...prev,
        promotion: {
          enabled: checked,
          expanded: checked,
        },
      }));
    } else if ("feedback-switch" === name) {
      setValues((prev) => ({
        ...prev,
        evaluation: {
          ...prev.evaluation,
          feedback: {
            enabled: checked,
            expanded: checked,
            threshold: 80,
          },
        },
      }));
    }
  };

  const onDelete = (questionId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this Question?",
      subTitle: "It will be deleted from case study PDF as well.",
      onConfirm: () => {
        handleDelete(questionId);
      },
    });
  };

  const handleDelete = (questionId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .deleteQuestion(questionId)
      .then((res) => {
        const updatedList = questions.filter((q) => q.id !== questionId);
        setQuestions(updatedList);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a question. Please try again later.",
          type: "error",
        });
      });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title={(isInEditMode ? assignment.title : "New") + " Project"}
        isChild={true}
      />
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <MuiInput
                  id="title"
                  name="title"
                  label="Title"
                  value={values.title}
                  onChange={(e) => handleInputChange(e)}
                  error={errors?.title}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {isInEditMode ? (
                  <AutoCompleteAsync
                    name="type"
                    value={{
                      id: values.type,
                      title: values.typeTitle
                        ? values.typeTitle
                        : capitalize(assignment.type),
                    }}
                    error={errors.type}
                    label={"Type"}
                    getOptions={() => assignmentService.types()}
                    onChange={onTypeChange}
                  />
                ) : (
                  <AutoCompleteAsync
                    name="type"
                    error={errors.type}
                    label={"Type"}
                    getOptions={() => assignmentService.types()}
                    onChange={onTypeChange}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MuiDatePicker
                  minDate={new Date()}
                  openTo="month"
                  views={["year", "month", "day"]}
                  id="dueDate"
                  name="dueDate"
                  label="Due Date"
                  value={values.dueDate}
                  onChange={(e) => handleInputChange(e)}
                  error={errors?.dueDate}
                />
              </Grid>
            </Grid>
            <Card elevation={3} sx={{ m: 1, mt: 2 }}>
              <CardHeader
                sx={{ m: 1 }}
                action={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={values.promotion.enabled}
                    onChange={handleSwitchChange}
                    name="promotion-switch"
                  />
                }
                title={
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="h6"
                    gutterBottom
                  >
                    Promotion
                  </Typography>
                }
                subheader={"Would you like to offer a discount to students?"}
              />
              <Collapse
                in={values.promotion?.expanded}
                timeout="auto"
                unmountOnExit
              >
                <CardContent sx={{ m: 0 }}>
                  <Promotion
                    caseStudyPrice={casestudy.price}
                    amount={values.discount}
                    onChange={handleInputChange}
                    percentage={promotionPercentage}
                    applyPromotion={(amount) => applyPromotion(amount)}
                    error={errors.promotion}
                  />
                </CardContent>
              </Collapse>
            </Card>

            <Card elevation={3} sx={{ m: 1, mt: 2 }}>
              <CardHeader
                sx={{ m: 1 }}
                action={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={values.evaluation?.enabled}
                    onChange={handleSwitchChange}
                    name="evaluation-switch"
                  />
                }
                title={
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="h6"
                    gutterBottom
                  >
                    Evaluation
                  </Typography>
                }
                subheader={
                  "Do you need to set up an evaluation for this project?"
                }
              />
              <Collapse
                in={values.evaluation?.expanded}
                timeout="auto"
                unmountOnExit
              >
                <CardContent sx={{ m: 0 }}>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {gradingTypesLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <Card elevation={3} sx={{ m: 0 }}>
                          <CardHeader
                            sx={{ m: 1 }}
                            title={
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="h6"
                                gutterBottom
                              >
                                Preferred Strictness in Grading
                              </Typography>
                            }
                            subheader={
                              "How strict and rigorous do you prefer the evaluation process to be?"
                            }
                          />
                          <CardContent sx={{ m: 4 }}>
                            <Grid container spacing={3} justifyContent="center">
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                              >
                                <Slider
                                  sx={{
                                    mt: 0,
                                    width: "70%",
                                  }}
                                  size="small"
                                  value={values.evaluation.grading?.value}
                                  onChange={(e, newVal) => {
                                    const type = gradingTypes.find(
                                      (item) => item.value === newVal
                                    );
                                    setValues((prev) => ({
                                      ...prev,
                                      evaluation: {
                                        ...prev.evaluation,
                                        grading: type,
                                      },
                                    }));
                                  }}
                                  marks={gradingTypes}
                                  step={1}
                                  // defaultValue={values.evaluation.grading}
                                  min={1}
                                  max={gradingTypes?.length}
                                  valueLabelFormat={(val) => {
                                    const type = gradingTypes.find(
                                      (item) => item.value === val
                                    );
                                    return type.title;
                                  }}
                                  valueLabelDisplay="on"
                                />
                              </Grid>
                              {errors?.grading && (
                                <Typography color={"error"} variant="caption">
                                  {errors.grading}
                                </Typography>
                              )}
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                      {!isInEditMode && (
                        <Card elevation={3} sx={{ mt: 2 }}>
                          <CardHeader
                            sx={{
                              m: 1,
                              mb: 0,
                              border: questionsError && "1px solid red",
                            }}
                            action={
                              <LoadingButton
                                color="success"
                                sx={{ mr: 1 }}
                                size="small"
                                onClick={() => openNewDialog()}
                                variant="outlined"
                                startIcon={<AddIcon />}
                                loadingPosition="start"
                              >
                                <span>New Question</span>
                              </LoadingButton>
                            }
                            title={
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="h6"
                                gutterBottom
                              >
                                List of case study questions
                              </Typography>
                            }
                          />
                          <CardContent sx={{ m: 0 }}>
                            {questions.length > 0 ? (
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                              >
                                {questions.map((question, qIndex) => (
                                  <ListItem
                                    key={qIndex}
                                    alignItems="flex-start"
                                    secondaryAction={
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        color="error"
                                        onClick={() => onDelete(question.id)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemText
                                      sx={{
                                        padding: 2,
                                        border:
                                          errors.questions &&
                                          errors.questions[question.id]
                                            ? "2px solid red"
                                            : "1px dotted",
                                      }}
                                      primary={
                                        <React.Fragment>
                                          <Typography variant="body1">
                                            {question.title}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                      secondary={
                                        <React.Fragment>
                                          <Card elevation={0} sx={{ mt: 2 }}>
                                            <CardHeader
                                              title={
                                                <Typography
                                                  sx={{ fontWeight: "bold" }}
                                                  variant="h6"
                                                  gutterBottom
                                                >
                                                  List of Criteria
                                                </Typography>
                                              }
                                              action={
                                                <LoadingButton
                                                  color="success"
                                                  sx={{ mr: 1 }}
                                                  size="small"
                                                  onClick={() =>
                                                    addCriteria(question.id)
                                                  }
                                                  variant="outlined"
                                                  startIcon={<AddIcon />}
                                                  loadingPosition="start"
                                                >
                                                  <span>New Criteria</span>
                                                </LoadingButton>
                                              }
                                            />
                                            <CardContent>
                                              <List
                                                dense={true}
                                                sx={{
                                                  width: "80%",
                                                }}
                                              >
                                                {question.criteria?.length > 0
                                                  ? question.criteria?.map(
                                                      (criterion, index) => (
                                                        <ListItem
                                                          key={index}
                                                          // alignItems="flex-start"
                                                          secondaryAction={
                                                            <IconButton
                                                              edge="end"
                                                              aria-label="delete"
                                                              color="error"
                                                              onClick={() =>
                                                                handleCriteriaDelete(
                                                                  question.id,
                                                                  criterion.id
                                                                )
                                                              }
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          }
                                                        >
                                                          <Grid
                                                            container
                                                            spacing={1}
                                                          >
                                                            <Grid item xs={9}>
                                                              <MuiInput
                                                                key={index}
                                                                name="criteria-title"
                                                                label="Title"
                                                                fullWidth
                                                                value={
                                                                  criterion.title
                                                                }
                                                                onChange={(e) =>
                                                                  handleCriteriaTitle(
                                                                    question.id,
                                                                    criterion.id,
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                              <TextField
                                                                key={index}
                                                                name="criteria-weight"
                                                                label={"Weight"}
                                                                type="number"
                                                                value={
                                                                  criterion.weight
                                                                }
                                                                InputLabelProps={{
                                                                  shrink: true,
                                                                }}
                                                                onChange={(e) =>
                                                                  handleCriteriaWeight(
                                                                    question.id,
                                                                    criterion.id,
                                                                    e
                                                                  )
                                                                }
                                                                InputProps={{
                                                                  endAdornment:
                                                                    (
                                                                      <InputAdornment position="end">
                                                                        %
                                                                      </InputAdornment>
                                                                    ),
                                                                }}
                                                              />
                                                            </Grid>
                                                          </Grid>
                                                        </ListItem>
                                                      )
                                                    )
                                                  : null}
                                              </List>
                                            </CardContent>
                                          </Card>
                                          <Typography color="error">
                                            {errors.questions &&
                                              errors.questions[question.id]}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            ) : (
                              <Typography
                                sx={{ ml: 2 }}
                                variant="body2"
                                gutterBottom
                                color={"secondary"}
                              >
                                {
                                  "No question found in the case study. At least one is required for evaluation."
                                }
                              </Typography>
                            )}
                          </CardContent>
                        </Card>
                      )}

                      {questions.length > 0 && (
                        <Card elevation={3} sx={{ m: 0, mt: 2 }}>
                          <CardHeader
                            sx={{ m: 1 }}
                            action={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={values.evaluation.feedback.enabled}
                                onChange={handleSwitchChange}
                                name="feedback-switch"
                              />
                            }
                            title={
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="h6"
                                gutterBottom
                              >
                                Feedback Request
                              </Typography>
                            }
                            subheader={
                              "Do you want to let students request feedback after grading?"
                            }
                          />
                          <Collapse
                            in={values.evaluation.feedback.expanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            <CardContent sx={{ m: 0 }}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <MuiDatePicker
                                    minDate={values.dueDate}
                                    openTo="month"
                                    views={["year", "month", "day"]}
                                    id="feedbackDueDate"
                                    name="feedbackDueDate"
                                    label="Due date to request for feedback"
                                    value={values.evaluation.feedback.dueDate}
                                    onChange={(e) =>
                                      setValues((prev) => ({
                                        ...prev,
                                        evaluation: {
                                          ...prev.evaluation,
                                          feedback: {
                                            ...prev.evaluation.feedback,
                                            dueDate: e.target.value,
                                          },
                                        },
                                      }))
                                    }
                                    error={errors?.feedbackDueDate}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography id="input-slider" gutterBottom>
                                    {"Allow Students Grading Below " +
                                      values.evaluation.feedback.threshold +
                                      " to Request Feedback"}
                                  </Typography>
                                  <Slider
                                    sx={{ mt: 1 }}
                                    size="small"
                                    value={values.evaluation.feedback.threshold}
                                    onChange={(e, newVal) =>
                                      setValues((prev) => ({
                                        ...prev,
                                        evaluation: {
                                          ...prev.evaluation,
                                          feedback: {
                                            ...prev.evaluation.feedback,
                                            threshold: newVal,
                                          },
                                        },
                                      }))
                                    }
                                    marks
                                    // step={1}
                                    // min={50}
                                    // max={100}
                                    valueLabelDisplay="on"
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Collapse>
                        </Card>
                      )}
                    </>
                  )}
                </CardContent>
              </Collapse>
            </Card>

            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction={{ xs: "column", sm: "row" }}
              sx={{ ml: 1, mt: 2 }}
            >
              <LoadingButton
                type="submit"
                color="success"
                variant="outlined"
                loading={submitting}
              >
                Submit
              </LoadingButton>
              <MuiButton
                text="Cancel"
                color="info"
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Stack>
          </Form>
        </React.Fragment>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={closeNewDialog}
        aria-labelledby="responsive-new-dialog"
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const qst = formJson.newQuestion?.trim();
            if (!qst) {
              setNewQuestionError("Question cannot be blank.");
              return;
            }
            setAddingNewQuestion(true);
            assignmentService
              .addNewQuestion(caseStudyId, qst)
              .then((res) => {
                const newItem = res.data;
                const newList = [
                  { title: qst, ...newItem, criteria: [] },
                  ...questions,
                ];
                setQuestions(newList);
              })
              .catch((e) => {
                console.error(`Error: ${e.message}`);
              })
              .finally(() => {
                setAddingNewQuestion(false);
                closeNewDialog();
              });
          },
        }}
      >
        <DialogTitle id="responsive-new-dialog">{"New Question"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add your question for evaluation. Note that it will also be
            included in the case study PDF.
          </DialogContentText>
        </DialogContent>
        <TextField
          sx={{ ml: 3, mr: 3 }}
          autoFocus
          multiline
          minRows={5}
          required
          margin="dense"
          id="newQuestion"
          name="newQuestion"
          label="Question"
          variant="outlined"
          error={Boolean(newQuestionError)}
          helperText={newQuestionError}
        />
        <DialogActions>
          <Button onClick={closeNewDialog}>Cancel</Button>
          <LoadingButton loading={addingNewQuestion} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
