import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import { MuiButton } from ".";
import SearchOffIcon from "@mui/icons-material/SearchOff";

const AutoCompleteAsync = ({
  value,
  name,
  label,
  getOptions,
  onChange,
  error = null,
  noOptionsText = "No Options",
  handleNotFound,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    getOptions()
      .then((response) => {
        if (active) {
          const newBtnOption = {
            title: "",
            isSpecial: true,
          };
          if (handleNotFound) {
            setOptions([newBtnOption, ...response.data]);
          } else {
            setOptions(response.data);
          }
        }
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
      });

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [loading]);

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        sx={{ flexGrow: 1 }}
        id="auto-complete-asynchronous"
        noOptionsText={noOptionsText}
        name={name}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        onChange={onChange}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title}
        options={options}
        loading={loading}
        renderOption={(props, option) => {
          if (option.isSpecial) {
            return (
              <li {...props} key="special-option">
                <MuiButton
                  size="small"
                  text={"Add new " + name}
                  color="info"
                  variant="text"
                  onClick={() => {
                    handleNotFound();
                  }}
                />
              </li>
            );
          }
          return <li {...props}>{option.title}</li>;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...other}
            {...(error && { error: true, helperText: error })}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />

      {handleNotFound && (
        <IconButton
          id="asyncautocomplete-notfound-icon"
          color="warning"
          onClick={() => handleNotFound()}
          size="large"
          sx={{ padding: "10px", ml: 2 }}
        >
          <SearchOffIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default AutoCompleteAsync;
