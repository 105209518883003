import React, { useState } from "react";
import { Grid, Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container, Paper } from "@mui/material";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { Form, useForm } from "../../../components/mui/useForm";
import { MuiInput } from "../../../components";
import LoadingButton from "@mui/lab/LoadingButton";
import * as authService from "../services/AuthService";
import Notification from "../../../components/mui/Notification";
import { useParams } from "react-router-dom";
import { LOGIN_ROUTE } from "../helper/Constants";
import { useNavigate } from "react-router-dom";
import { isPasswordValid } from ".";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    margin: "20px auto",
  },
  btn: {
    margin: "8px 0",
  },
}));

const initialFValues = {
  userId: "",
  password: "",
};

export default function ResetPassword(props) {
  const classes = useStyles();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues) {
      if (fieldValues.password) {
        if (!isPasswordValid(fieldValues.password)) {
          temp.password =
            "Must be at least 8 characters with at least one number.";
        } else temp.password = "";
      } else {
        temp.password = "This field is required.";
      }
    }
    if ("passwordConfirmation" in fieldValues) console.log(values);
    temp.passwordConfirmation =
      fieldValues.passwordConfirmation === values.password
        ? ""
        : "Passwords not match.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    values.userId = userId;
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      authService
        .resetPassword(values)
        .then((response) => {
          resetForm();
          setLoading(false);
          setNotify({
            isOpen: true,
            message: "Your password changed successfully",
            type: "success",
          });
          setTimeout(() => {
            // Add your logic for the transition
            navigate(LOGIN_ROUTE);
          }, 1000);
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setLoading(false);
          setNotify({
            isOpen: true,
            message: "Something went wrong. Please try again later.",
            type: "error",
          });
        });
    }
  };

  const { values, errors, resetForm, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Form onSubmit={handleSubmit}>
        <Paper elevation={10} className={classes.paper}>
          <Grid container justifyContent="center">
            <Grid item align="center" xs={12}>
              <Avatar sx={{ bgcolor: "#1bbd7e" }}>
                <LockResetOutlinedIcon />
              </Avatar>
              <h2>Reset Password</h2>
              <MuiInput
                name="password"
                label="Password"
                type="password"
                value={values.password}
                onChange={handleInputChange}
                error={errors.password}
              />
              <MuiInput
                name="passwordConfirmation"
                label="Confirm Password"
                type="password"
                value={values.passwordConfirmation}
                onChange={handleInputChange}
                error={errors.passwordConfirmation}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                  mb: 1,
                  ml: 3,
                }}
              >
                <LoadingButton
                  type="submit"
                  color="success"
                  variant="contained"
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
