import React from "react";
import { Typography, Box, IconButton } from "@mui/material";

export default function IconWithText({
  icon,
  text,
  variant = "caption",
  onClick,
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
    >
      <IconButton color="primary" onClick={onClick}>
        {icon}
      </IconButton>
      <Typography
        variant={variant}
        onClick={onClick}
        style={{
          cursor: "pointer",
          color: "black", // Adjust the color to match your theme
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
