import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Grid,
  Skeleton,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import ReviewCard from "./ReviewCard";
import EditableField from "../../../../components/EditableTextField";
import * as casestudyService from "../../services/CasestudyService";
import Notification from "../../../../components/mui/Notification";
import { isEpmty } from "../../../../helper/TextHelper";
import EditableReviewCard from "./EditableReviewCard";
import EditableReviewCardWithTitle from "./EditableReviewCardWithTitle";
import SaveLoadingButton from "../../../../components/SaveLoadingButton";
import { Assistant, TourRide, TourStep } from "../../../../components";

const Review = ({
  course,
  formValues,
  caseStudy,
  setState,
  updateLoadingStatus,
}) => {
  const bizValues = formValues.biz;
  const companyValues = formValues.company;
  const industryValues = formValues.industry;
  const baseInfoValues = formValues.baseInfo;
  const caseStudyId = formValues.caseStudyId;
  const concept = formValues.concept;

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [titleIntroductionLoading, setTitleIntroductionLoading] = useState();
  const [introductionSaving, setIntroductionSaving] = useState();
  const [introductionSaved, setIntroductionSaved] = useState();
  const [introductionChanged, setIntroductionChanged] = useState();
  const [titleSaving, setTitleSaving] = useState();
  const [titleSaved, setTitleSaved] = useState();
  const [titleChanged, setTitleChanged] = useState();
  const [industryDescLoading, setIndustryDescLoading] = useState();
  const [industryDescSaving, setIndustryDescSaving] = useState();
  const [industryDescSaved, setIndustryDescSaved] = useState();
  const [industryDescChanged, setIndustryDescChanged] = useState();
  const [macroAnalysisSaving, setMacroAnalysisSaving] = useState();
  const [macroAnalysisLoading, setMacroAnalysisLoading] = useState();
  const [macroAnalysisSaved, setMacroAnalysisSaved] = useState();
  const [macroAnalysisChanged, setMacroAnalysisChanged] = useState();
  const [microAnalysisSaving, setMicroAnalysisSaving] = useState();
  const [microAnalysisLoading, setMicroAnalysisLoading] = useState();
  const [microAnalysisSaved, setMicroAnalysisSaved] = useState();
  const [microAnalysisChanged, setMicroAnalysisChanged] = useState();
  const [industryTrendsLoading, setIndustryTrendsLoading] = useState();
  const [industryTrendsSaving, setIndustryTrendsSaving] = useState();
  const [industryTrendsSaved, setIndustryTrendsSaved] = useState();
  const [industryTrendsChanged, setIndustryTrendsChanged] = useState();
  const [companyHistoryLoading, setCompanyHistoryLoading] = useState();
  const [companyHistorySaving, setCompanyHistorySaving] = useState();
  const [companyHistorySaved, setCompanyHistorySaved] = useState();
  const [companyHistoryChanged, setCompanyHistoryChanged] = useState();
  const [marketSegmentLoading, setMarketSegmentLoading] = useState();
  const [marketSegmentSaving, setMarketSegmentSaving] = useState();
  const [marketSegmentSaved, setMarketSegmentSaved] = useState();
  const [marketSegmentChanged, setMarketSegmentChanged] = useState();
  const [brandsLoading] = useState();
  const [brandsSaving, setBrandsSaving] = useState();
  const [productAndServicesLoading] = useState();
  const [competitiveAdvantageLoading, setCompetitiveAdvantageLoading] =
    useState();
  const [competitiveAdvantageSaving, setCompetitiveAdvantageSaving] =
    useState();
  const [competitiveAdvantageSaved, setCompetitiveAdvantageSaved] = useState();
  const [competitiveAdvantageChanged, setCompetitiveAdvantageChanged] =
    useState();
  const [competitorsAnalysisLoading, setCompetitorsAnalysisLoading] =
    useState();
  const [competitorsAnalysisSaving, setCompetitorsAnalysisSaving] = useState();
  const [competitorsAnalysisSaved, setCompetitorsAnalysisSaved] = useState();
  const [competitorsAnalysisChanged, setCompetitorsAnalysisChanged] =
    useState();
  const [problemsLoading, setProblemsLoading] = useState();
  const [problemsSaving, setProblemsSaving] = useState();
  const [problemsSaved, setProblemsSaved] = useState();
  const [problemsChanged, setProblemsChanged] = useState();
  const [waitingForProblems, setWitingForProblems] = useState();
  const [problemsRetryCountdown, setProblemsRetryCountdown] = useState(null);
  const [setProblmsLoadingBtnTitle] = useState("Assistant");

  const setCaseStudyTitle = (value) => {
    setState((prevValues) => ({
      ...prevValues,
      title: value,
    }));
  };

  const setCaseStudyIntroduction = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      introductions: newList,
    }));
  };

  const applyIntroductionChanges = () => {
    setIntroductionSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.introductions,
    };
    casestudyService
      .updateIntroduction(request)
      .then((ignored) => setIntroductionChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update introduction. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIntroductionSaving(false);
          setIntroductionSaved(true);
        }, 1000);
        setTimeout(() => {
          setIntroductionSaved(false);
        }, 4000);
      });
  };

  const applyTitleChanges = () => {
    setTitleSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      title: caseStudy.title,
    };
    casestudyService
      .updateTitle(request)
      .then((ignored) => setTitleChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update title. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setTitleSaving(false);
          setTitleSaved(true);
        }, 1000);
        setTimeout(() => {
          setTitleSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyIndustryDescription = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      industryDescriptions: newList,
    }));
  };

  const applyIndustryDescriptionChanges = () => {
    setIndustryDescSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      descriptions: caseStudy.industryDescriptions,
    };
    casestudyService
      .updateIndustryDescription(request)
      .then((ignored) => setIndustryDescChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update descriptions. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIndustryDescSaving(false);
          setIndustryDescSaved(true);
        }, 1000);
        setTimeout(() => {
          setIndustryDescSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyIndustryTrends = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      industryTrends: newList,
    }));
  };

  const applyIndustryTrendsChanges = () => {
    setIndustryTrendsSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.industryTrends,
    };
    casestudyService
      .updateIndustryKeyTrends(request)
      .then((ignored) => setIndustryTrendsChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update industry trends. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIndustryTrendsSaving(false);
          setIndustryTrendsSaved(true);
        }, 1000);
        setTimeout(() => {
          setIndustryTrendsSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyCompanyHistory = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      companyHistoryList: newList,
    }));
  };

  const applyCompanyHistoryChanges = () => {
    setCompanyHistorySaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.companyHistoryList,
    };
    casestudyService
      .updateCompanyBackground(request)
      .then((ignored) => setCompanyHistoryChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update compnay history. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setCompanyHistorySaving(false);
          setCompanyHistorySaved(true);
        }, 1000);
        setTimeout(() => {
          setCompanyHistorySaved(false);
        }, 4000);
      });
  };

  const applyCaseStudyMarketSegmentChanges = () => {
    setMarketSegmentSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.marketSegmentList,
    };
    casestudyService
      .updateMarketSegment(request)
      .then((ignored) => setMarketSegmentChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update market segment. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setMarketSegmentSaving(false);
          setMarketSegmentSaved(true);
        }, 1000);
        setTimeout(() => {
          setMarketSegmentSaved(false);
        }, 4000);
      });
  };

  const onListItemChange = (list, listName, id, value) => {
    const updatedList = list.map((item) =>
      item.id === id ? { ...item, value } : item
    );
    setState((prevValues) => ({
      ...prevValues,
      [listName]: updatedList,
    }));
  };

  const toggleListItem = (list, listName, id, value) => {
    const updatedList = list.map((item) =>
      item.id === id ? { ...item, isActive: value } : item
    );
    setState((prevValues) => ({
      ...prevValues,
      [listName]: updatedList,
    }));
  };

  const onBrandsTitleChange = (id, value) => {
    const updatedList = caseStudy.brands.map((item) =>
      item.id === id ? { ...item, title: value } : item
    );
    setState((prevValues) => ({
      ...prevValues,
      brands: updatedList,
    }));
  };

  const onBrandsDescriptionChange = (id, value) => {
    const updatedList = caseStudy.brands.map((item) =>
      item.id === id ? { ...item, description: value } : item
    );
    setState((prevValues) => ({
      ...prevValues,
      brands: updatedList,
    }));
  };

  const applyBrandsChanges = () => {
    setBrandsSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.brands,
    };
    casestudyService
      .updateBrands(request)
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update brands. Please try again later",
          type: "error",
        });
      })
      .finally(() => setBrandsSaving(false));
  };

  const setCaseStudyDetailsList = (listName, list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      [listName]: newList,
    }));
  };

  const applyCompetitiveAdvantageChanges = () => {
    setCompetitiveAdvantageSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.competitiveAdvantageList,
    };
    casestudyService
      .updateCompetitiveAdvantage(request)
      .then((ignored) => setCompetitiveAdvantageChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not update competitive advantages. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setCompetitiveAdvantageSaving(false);
          setCompetitiveAdvantageSaved(true);
        }, 1000);
        setTimeout(() => {
          setCompetitiveAdvantageSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyCompetitorsAnalysis = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      competitorsAnalysisList: newList,
    }));
  };

  const applyCompetitorsAnalysisChanges = () => {
    setCompetitorsAnalysisSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.competitorsAnalysisList,
    };
    casestudyService
      .updateCompetitorsAnalysis(request)
      .then((ignored) => setCompetitorsAnalysisChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not update conpetitors analysis. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setCompetitorsAnalysisSaving(false);
          setCompetitorsAnalysisSaved(true);
        }, 1000);
        setTimeout(() => {
          setCompetitorsAnalysisSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyMacroAnalysis = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      macroAnalysisList: newList,
    }));
  };

  const applyMacroAnalysisChanges = () => {
    setMacroAnalysisSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.macroAnalysisList,
    };
    casestudyService
      .updateMacroAnalysis(request)
      .then((ignored) => setMacroAnalysisChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update macro analysis. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setMacroAnalysisSaving(false);
          setMacroAnalysisSaved(true);
        }, 1000);
        setTimeout(() => {
          setMacroAnalysisSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyMicroAnalysis = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      microAnalysisList: newList,
    }));
  };

  const applyMicroAnalysisChanges = () => {
    setMicroAnalysisSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.microAnalysisList,
    };
    casestudyService
      .updateMicroAnalysis(request)
      .then((ignored) => setMicroAnalysisChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update micro analysis. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setMicroAnalysisSaving(false);
          setMicroAnalysisSaved(true);
        }, 1000);
        setTimeout(() => {
          setMicroAnalysisSaved(false);
        }, 4000);
      });
  };

  const onProductAndServicesChange = (id, value) => {
    const updatedList = caseStudy.productAndServices.map((item) =>
      item.id === id ? { ...item, value } : item
    );
    setState((prevValues) => ({
      ...prevValues,
      productAndServices: updatedList,
    }));
  };

  const toggleProductAndServices = (id, value) => {
    const updatedList = caseStudy.productAndServices.map((item) =>
      item.id === id ? { ...item, isActive: value } : item
    );
    setState((prevValues) => ({
      ...prevValues,
      productAndServices: updatedList,
    }));
  };

  const getIndustryDescription = () => {
    setIndustryDescLoading(true);
    updateLoadingStatus("industryDesc", true);
    casestudyService
      .getIndustryDescription(
        caseStudyId,
        industryValues.name,
        companyValues.territory,
        concept,
        course.field,
        companyValues.names[0],
        industryValues.province
      )
      .then((res) => {
        setCaseStudyIndustryDescription(res.data);
        setIndustryDescChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not fetch industry description. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setIndustryDescLoading(false);
        updateLoadingStatus("industryDesc", false);
      });
  };

  const getIndustryKeyTrends = () => {
    setIndustryTrendsLoading(true);
    updateLoadingStatus("industryKeyTrends", true);
    casestudyService
      .getIndustryKeyTrends(
        caseStudyId,
        industryValues.name,
        companyValues.territory,
        industryValues.country,
        industryValues.province,
        course.field,
        formValues.concept,
        companyValues.names[0]
      )
      .then((res) => {
        setCaseStudyIndustryTrends(res.data);
        setIndustryTrendsChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not fetch industry key trends. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setIndustryTrendsLoading(false);
        updateLoadingStatus("industryKeyTrends", false);
      });
  };

  const getHistory = () => {
    setCompanyHistoryLoading(true);
    updateLoadingStatus("history", true);
    const request = {
      caseStudyId: caseStudyId,
      concept: formValues.concept,
      companyName: companyValues.names[0],
      field: course.field,
      chapterTitle: baseInfoValues.chapters[0],
    };
    casestudyService
      .getCompanyBackground(request)
      .then((res) => {
        setCaseStudyCompanyHistory(res.data);
        setCompanyHistoryChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch company Histroy. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setCompanyHistoryLoading(false);
        updateLoadingStatus("history", false);
      });
  };

  const getCompetitorsAnalysis = () => {
    setCompetitorsAnalysisLoading(true);
    updateLoadingStatus("compAnalysis", true);
    const request = {
      caseStudyId: caseStudyId,
      concept: formValues.concept,
      industry: industryValues.name,
      marketTerritory: companyValues.territory,
      companyName: companyValues.names[0],
      country: industryValues.country,
      province: industryValues.province,
      competitors: companyValues.competitors.join(", "),
      chapterTopics: baseInfoValues.chapterTopics?.join(", "),
    };
    casestudyService
      .getCompanyCompetitorsAnalysis(request)
      .then((res) => {
        setCaseStudyCompetitorsAnalysis(res.data);
        setCompetitorsAnalysisChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not fetch company competitive analysis. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setCompetitorsAnalysisLoading(false);
        updateLoadingStatus("compAnalysis", false);
      });
  };

  const getCompetitiveAdvantage = () => {
    setCompetitiveAdvantageLoading(true);
    updateLoadingStatus("compAdvantages", true);
    const request = {
      caseStudyId: caseStudyId,
      companyName: companyValues.names[0],
      country: industryValues.country,
      province: industryValues.province,
      field: course.field,
    };
    casestudyService
      .getCompanyCompetitiveAdvantage(request)
      .then((res) => {
        setCaseStudyDetailsList("competitiveAdvantageList", res.data);
        setCompetitiveAdvantageChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not fetch company competitive advantages. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setCompetitiveAdvantageLoading(false);
        updateLoadingStatus("compAdvantages", false);
      });
  };

  const getMarketSegment = () => {
    setMarketSegmentLoading(true);
    updateLoadingStatus("marketSegment", true);
    casestudyService
      .getMarketSegment(
        caseStudyId,
        companyValues.names[0],
        industryValues.country
      )
      .then((res) => {
        setCaseStudyDetailsList("marketSegmentList", res.data);
        setMarketSegmentChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not fetch company market segment. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setMarketSegmentLoading(false);
        updateLoadingStatus("marketSegment", false);
      });
  };

  const getMacroAnalysis = () => {
    setMacroAnalysisLoading(true);
    updateLoadingStatus("macroAnalysis", true);
    const request = {
      caseStudyId: caseStudyId,
      variables: bizValues.macros?.join(", "),
      industry: industryValues.name,
      country: industryValues.country,
      province: industryValues.province,
      concept: formValues.concept,
      companyName: companyValues.names[0],
      major: course.field,
    };
    casestudyService
      .getMacroAnalysis(request)
      .then((res) => {
        setCaseStudyMacroAnalysis(res.data);
        setMacroAnalysisChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch macro analysis. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setMacroAnalysisLoading(false);
        updateLoadingStatus("macroAnalysis", false);
      });
  };

  const getMicroAnalysis = () => {
    setMicroAnalysisLoading(true);
    updateLoadingStatus("microAnalysis", true);
    const request = {
      caseStudyId: caseStudyId,
      variables: bizValues.micros?.join(", "),
      industry: industryValues.name,
      country: industryValues.country,
      province: industryValues.province,
      concept: formValues.concept,
      companyName: companyValues.names[0],
      major: course.field,
    };
    casestudyService
      .getMicroAnalysis(request)
      .then((res) => {
        setCaseStudyMicroAnalysis(res.data);
        setMicroAnalysisChanged(false);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch micro analysis. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setMicroAnalysisLoading(false);
        updateLoadingStatus("microAnalysis", false);
      });
  };

  // Problems
  const getProblems = () => {
    setProblemsLoading(true);
    updateLoadingStatus("problems", true);
    casestudyService
      .getProblemStory(caseStudyId)
      .then((res) => {
        setCaseStudyProblems(res.data);
        setProblemsChanged(false);
      })
      .catch((e) => {
        console.error(e);
        if (e.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (e.response.status === 404) {
            setNotify({
              isOpen: true,
              message: "Problems creation still in progress...",
              type: "warning",
            });
            handle404Error();
          } else {
            setNotify({
              isOpen: true,
              message: "Something went wrong. Please try again later.",
              type: "error",
            });
          }
        } else {
          setNotify({
            isOpen: true,
            message: "Could not fetch problem story. Please try again later",
            type: "error",
          });
        }
      })
      .finally(() => {
        setProblemsLoading(false);
        updateLoadingStatus("problems", false);
      });
  };

  const handle404Error = () => {
    setWitingForProblems(true);
    let countdown = 20;
    const intervalId = setInterval(() => {
      countdown--;
      if (countdown === 0) {
        clearInterval(intervalId);
        setProblmsLoadingBtnTitle("Assistant");
        setWitingForProblems(false);
      } else {
        setProblmsLoadingBtnTitle(
          `Progressing... Retry in ${countdown} seconds`
        );
      }
    }, 1000);
    setProblemsRetryCountdown(intervalId);
  };

  const setCaseStudyProblems = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      title: item.title,
      stories: item.stories.map((story, ind) => ({
        id: story.id,
        text: story.text,
        isActive: true,
      })),
      isActive: true,
    }));
    setState((prevValues) => ({
      ...prevValues,
      problemsList: newList,
    }));
  };

  const onProblemTitleChange = (index, value) => {
    const problems = [...caseStudy.problemsList];
    const problem = problems[index];
    problems[index] = { ...problem, title: value };
    setState((prevValues) => ({
      ...prevValues,
      problemsList: problems,
    }));
  };

  const toggleProblemTitle = (index, value) => {
    const problems = [...caseStudy.problemsList];
    const problem = problems[index];

    const updatedProblem = {
      ...problem,
      stories: problem.stories.map((story) => ({ ...story, isActive: value })),
      isActive: value,
    };
    problems[index] = updatedProblem;
    setState((prevValues) => ({
      ...prevValues,
      problemsList: problems,
    }));
  };

  const handleProblemStoryChange = (problemIndex, storyIndex) => (event) => {
    setProblemsChanged(true);
    const newValue = event.target.value;
    const problems = [...caseStudy.problemsList];
    const stories = problems[problemIndex].stories;
    const changedStory = stories[storyIndex];

    stories[storyIndex] = { ...changedStory, text: newValue };
    setState((prevValues) => ({
      ...prevValues,
      problemsList: problems,
    }));
  };

  const toggleProblemStory = (problemIndex, storyIndex, value) => {
    setProblemsChanged(true);
    const problems = [...caseStudy.problemsList];
    const stories = problems[problemIndex].stories;
    const changedStory = stories[storyIndex];

    stories[storyIndex] = { ...changedStory, isActive: value };
    setState((prevValues) => ({
      ...prevValues,
      problemsList: problems,
    }));
  };

  const applyProblemChanges = () => {
    setProblemsSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: caseStudy.problemsList,
    };
    casestudyService
      .updateProblemStory(request)
      .then((ignored) => setProblemsChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update problem. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setProblemsSaving(false);
          setProblemsSaved(true);
        }, 1000);
        setTimeout(() => {
          setProblemsSaved(false);
        }, 4000);
      });
  };
  // End of Problems

  // const getProductAndServices = () => {
  //   setProductAndServicesLoading(true);
  //   updateLoadingStatus("producationServices", true);
  //   console.log("getProductAndServices...");
  //   casestudyService
  //     .getProductAndServices(
  //       companyValues.names[0],
  //       industryValues.country,
  //       industryValues.province
  //     )
  //     .then((res) => {
  //       setCaseStudyCompanyProductAndServices(res.data);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setNotify({
  //         isOpen: true,
  //         message:
  //           "Could not fetch company product and services. Please try again later",
  //         type: "error",
  //       });
  //     })
  //     .finally(() => {
  //       setProductAndServicesLoading(false);
  //       updateLoadingStatus("producationServices", false);
  //     });
  // };

  // const getBrands = () => {
  //   setBrandsLoading(true);
  //   updateLoadingStatus("brands", true);
  //   console.log("getBrands...");
  //   casestudyService
  //     .getCompanyBrands(
  //       caseStudyId,
  //       companyValues.names[0],
  //       companyValues.territory,
  //       industryValues.province
  //     )
  //     .then((res) => {
  //       setCaseStudyCompanyBrands(res.data);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setNotify({
  //         isOpen: true,
  //         message: "Could not fetch company brands. Please try again later",
  //         type: "error",
  //       });
  //     })
  //     .finally(() => {
  //       setBrandsLoading(false);
  //       updateLoadingStatus("brands", false);
  //     });
  // };

  useEffect(() => {
    return () => {
      // Clean up the countdown interval when component unmounts
      clearInterval(problemsRetryCountdown);
    };
  }, [problemsRetryCountdown]);

  useEffect(() => {
    const getTitleAndIntroduction = () => {
      setTitleIntroductionLoading(true);
      updateLoadingStatus("titleIntro", true);
      const request = {
        courseId: course.id,
        major: course.field,
        caseStudyId: caseStudyId,
        companyName: companyValues.names[0],
        concept: concept,
      };
      casestudyService
        .getTitleAndIntroduction(request)
        .then((res) => {
          setCaseStudyTitle(res.data.title);
          setCaseStudyIntroduction(res.data.introductions);
        })
        .catch((e) => {
          console.error(e);
          setNotify({
            isOpen: true,
            message:
              "Could not fetch title and introduction. Please try again later",
            type: "error",
          });
        })
        .finally(() => {
          setTitleIntroductionLoading(false);
          updateLoadingStatus("titleIntro", false);
        });
    };

    // Call the functions to send queries when the component mounts
    if (!titleIntroductionLoading && isEpmty(caseStudy.title)) {
      getTitleAndIntroduction();
    } else
      console.log("Title and introduction already exists or is in progess");

    // eslint-disable-next-line
  }, []);

  const reviewTourSteps = [
    {
      target: "#review-page-step-1",
      content: (
        <TourStep
          title={"Edit"}
          description={
            "Click on a text block to add, edit, or delete content. Remember to save your changes once you're done editing"
          }
        />
      ),
    },
    {
      target: "#review-page-step-2",
      content: (
        <TourStep
          title={"Save Changes"}
          description={"Save your changes by clicking the Save Changes button."}
        />
      ),
    },
    {
      target: "#editable-review-card-delete",
      content: (
        <TourStep
          title={"Delete"}
          description={
            "Click on a delete icon to delete a paragraph, and don’t forget to save your changes by clicking the Save Changes button."
          }
        />
      ),
    },
    {
      target: "#bcl-assistant",
      content: (
        <TourStep
          title={"Add block to the case"}
          description={
            "If you need to include any of the presented blocks in the case study, click on BCL Assistant."
          }
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <TourRide
        name="case-study-creation-review-page"
        steps={reviewTourSteps}
      />
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Important</AlertTitle>
        In this step, you finalize your case study. Avoid using the browser’s
        back button, as it will erase your progress. Use the Assistant to access
        the blocks you need for your work. You can add or delete content if you
        need. Do not forget to Save Changes. Using more blocks will add pages to
        the final draft. Selecting all fields will result in a case study of
        around 20 pages.
      </Alert>
      <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
        <React.Fragment>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid container spacing={0}>
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      Case Study Title
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ marginLeft: "auto" }}
                    id="review-page-step-2"
                  >
                    <SaveLoadingButton
                      isDisabled={!titleChanged}
                      handleClick={applyTitleChanges}
                      isLoading={titleSaving}
                      isSaved={titleSaved}
                    />
                  </Grid>
                </Grid>
                {titleIntroductionLoading ? (
                  <Skeleton variant="text" sx={{ fontSize: "4rem" }} />
                ) : (
                  <EditableField
                    id="review-page-step-1"
                    value={caseStudy.title}
                    onChange={(e) => {
                      setTitleChanged(true);
                      setCaseStudyTitle(e.target.value);
                    }}
                  />
                )}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                id="review-page-step-3"
                variant="outlined"
                sx={{ my: { xs: 1 }, p: { xs: 2 } }}
              >
                <Grid container spacing={0}>
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      Introduction
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <SaveLoadingButton
                      isDisabled={!introductionChanged}
                      handleClick={applyIntroductionChanges}
                      isLoading={introductionSaving}
                      isSaved={introductionSaved}
                    />
                  </Grid>
                </Grid>
                {titleIntroductionLoading ? (
                  <>
                    <Skeleton variant="rounded" height={60} />
                    <br />
                    <Skeleton variant="rounded" height={60} />{" "}
                  </>
                ) : (
                  caseStudy.introductions?.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <EditableReviewCard
                        shouldBeExpanded={true}
                        key={index}
                        content={item.value}
                        isActive={item.isActive}
                        onChange={(e) => {
                          setIntroductionChanged(true);
                          onListItemChange(
                            caseStudy.introductions,
                            "introductions",
                            item.id,
                            e.target.value
                          );
                        }}
                        toggle={(value) => {
                          setIntroductionChanged(true);
                          toggleListItem(
                            caseStudy.introductions,
                            "introductions",
                            item.id,
                            value
                          );
                        }}
                      />
                    </Grid>
                  ))
                )}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      Industry Description
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={caseStudy.industryDescriptions?.length > 0}
                      onClick={getIndustryDescription}
                      loading={industryDescLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!industryDescChanged}
                      handleClick={applyIndustryDescriptionChanges}
                      isLoading={industryDescSaving}
                      isSaved={industryDescSaved}
                    />
                  </Grid>
                </Grid>
                {industryDescLoading
                  ? [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        height={60}
                        sx={{ m: 1 }}
                      />
                    ))
                  : caseStudy.industryDescriptions?.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <EditableReviewCard
                          key={index}
                          content={item.value}
                          isActive={item.isActive}
                          onChange={(e) => {
                            setIndustryDescChanged(true);
                            onListItemChange(
                              caseStudy.industryDescriptions,
                              "industryDescriptions",
                              item.id,
                              e.target.value
                            );
                          }}
                          toggle={(value) => {
                            setIndustryDescChanged(true);
                            toggleListItem(
                              caseStudy.industryDescriptions,
                              "industryDescriptions",
                              item.id,
                              value
                            );
                          }}
                          // onChange={(e) =>
                          //   onIndustryDescriptionsChange(
                          //     item.id,
                          //     e.target.value
                          //   )
                          // }
                          // toggle={(value) =>
                          //   toggleIndustryDescriptions(item.id, value)
                          // }
                        />
                      </Grid>
                    ))}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      Industry Trends and Market Dynamics Analysis
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={caseStudy.industryTrends?.length > 0}
                      onClick={getIndustryKeyTrends}
                      loading={industryTrendsLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!industryTrendsChanged}
                      handleClick={applyIndustryTrendsChanges}
                      isLoading={industryTrendsSaving}
                      isSaved={industryTrendsSaved}
                    />
                  </Grid>
                </Grid>
                {industryTrendsLoading
                  ? [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        height={60}
                        sx={{ m: 1 }}
                      />
                    ))
                  : caseStudy.industryTrends?.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <EditableReviewCard
                          key={index}
                          content={item.value}
                          isActive={item.isActive}
                          onChange={(e) => {
                            setIndustryTrendsChanged(true);
                            onListItemChange(
                              caseStudy.industryTrends,
                              "industryTrends",
                              item.id,
                              e.target.value
                            );
                          }}
                          toggle={(value) => {
                            setIndustryTrendsChanged(true);
                            toggleListItem(
                              caseStudy.industryTrends,
                              "industryTrends",
                              item.id,
                              value
                            );
                          }}
                        />
                      </Grid>
                    ))}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      {companyValues.names[0] + " History"}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={caseStudy.companyHistoryList?.length > 0}
                      onClick={getHistory}
                      loading={companyHistoryLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!companyHistoryChanged}
                      handleClick={applyCompanyHistoryChanges}
                      isLoading={companyHistorySaving}
                      isSaved={companyHistorySaved}
                    />
                  </Grid>
                </Grid>
                {companyHistoryLoading
                  ? [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        height={60}
                        sx={{ m: 1 }}
                      />
                    ))
                  : caseStudy.companyHistoryList?.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <EditableReviewCard
                          key={index}
                          content={item.value}
                          isActive={item.isActive}
                          onChange={(e) => {
                            setCompanyHistoryChanged(true);
                            onListItemChange(
                              caseStudy.companyHistoryList,
                              "companyHistoryList",
                              item.id,
                              e.target.value
                            );
                          }}
                          toggle={(value) => {
                            setCompanyHistoryChanged(true);
                            toggleListItem(
                              caseStudy.companyHistoryList,
                              "companyHistoryList",
                              item.id,
                              value
                            );
                          }}
                        />
                      </Grid>
                    ))}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      {companyValues.names[0] + " Competitive Advantages"}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={caseStudy.competitiveAdvantageList?.length > 0}
                      onClick={getCompetitiveAdvantage}
                      loading={competitiveAdvantageLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!competitiveAdvantageChanged}
                      handleClick={applyCompetitiveAdvantageChanges}
                      isLoading={competitiveAdvantageSaving}
                      isSaved={competitiveAdvantageSaved}
                    />
                  </Grid>
                </Grid>
                {competitiveAdvantageLoading
                  ? [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        height={60}
                        sx={{ m: 1 }}
                      />
                    ))
                  : caseStudy.competitiveAdvantageList?.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <EditableReviewCard
                          key={index}
                          content={item.value}
                          isActive={item.isActive}
                          onChange={(e) => {
                            setCompetitiveAdvantageChanged(true);
                            onListItemChange(
                              caseStudy.competitiveAdvantageList,
                              "competitiveAdvantageList",
                              item.id,
                              e.target.value
                            );
                          }}
                          toggle={(value) => {
                            setCompetitiveAdvantageChanged(true);
                            toggleListItem(
                              caseStudy.competitiveAdvantageList,
                              "competitiveAdvantageList",
                              item.id,
                              value
                            );
                          }}
                        />
                      </Grid>
                    ))}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      {companyValues.names[0] + " Market Segment Analysis"}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={caseStudy.marketSegmentList?.length > 0}
                      onClick={getMarketSegment}
                      loading={marketSegmentLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!marketSegmentChanged}
                      handleClick={applyCaseStudyMarketSegmentChanges}
                      isLoading={marketSegmentSaving}
                      isSaved={marketSegmentSaved}
                    />
                  </Grid>
                </Grid>
                {marketSegmentLoading
                  ? [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        height={60}
                        sx={{ m: 1 }}
                      />
                    ))
                  : caseStudy.marketSegmentList?.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <EditableReviewCard
                          key={index}
                          content={item.value}
                          isActive={item.isActive}
                          onChange={(e) => {
                            setMarketSegmentChanged(true);
                            onListItemChange(
                              caseStudy.marketSegmentList,
                              "marketSegmentList",
                              item.id,
                              e.target.value
                            );
                          }}
                          toggle={(value) => {
                            setMarketSegmentChanged(true);
                            toggleListItem(
                              caseStudy.marketSegmentList,
                              "marketSegmentList",
                              item.id,
                              value
                            );
                          }}
                        />
                      </Grid>
                    ))}
              </Paper>
            </Grid>

            {false && (
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                  <Grid container spacing={0}>
                    <Grid item>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="h6"
                        gutterBottom
                      >
                        Company Brands
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Assistant
                        onClick={applyBrandsChanges}
                        loading={brandsSaving}
                      />
                    </Grid>
                  </Grid>
                  {brandsLoading ? (
                    <>
                      <Skeleton variant="rounded" height={60} />
                      <br />
                      <Skeleton variant="rounded" height={60} />{" "}
                    </>
                  ) : (
                    caseStudy.brands?.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <EditableReviewCardWithTitle
                          key={index}
                          title={item.title}
                          content={item.description}
                          isActive={item.isActive}
                          onTitleChange={(e) =>
                            onBrandsTitleChange(item.id, e.target.value)
                          }
                          onChange={(e) =>
                            onBrandsDescriptionChange(item.id, e.target.value)
                          }
                          toggle={(value) =>
                            toggleListItem(
                              caseStudy.brands,
                              "brands",
                              item.id,
                              value
                            )
                          }
                        />
                      </Grid>
                    ))
                  )}
                </Paper>
              </Grid>
            )}

            {false && (
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="h6"
                    gutterBottom
                  >
                    Product and Services
                  </Typography>
                  {productAndServicesLoading ? (
                    <Skeleton variant="rounded" height={90} />
                  ) : (
                    caseStudy.productAndServices?.map((item, index) => (
                      <Grid item xs={12} sx={{ m: 2 }} key={index}>
                        <ReviewCard
                          key={index}
                          headerTitle={"No. " + (index + 1)}
                          content={item.value}
                          isActive={item.isActive}
                          onChange={(e) =>
                            onProductAndServicesChange(item.id, e.target.value)
                          }
                          toggle={(value) =>
                            toggleProductAndServices(item.id, value)
                          }
                        />
                      </Grid>
                    ))
                  )}
                </Paper>
              </Grid>
            )}

            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      Competitors Analysis
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={caseStudy.competitorsAnalysisList?.length > 0}
                      onClick={getCompetitorsAnalysis}
                      loading={competitorsAnalysisLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!competitorsAnalysisChanged}
                      handleClick={applyCompetitorsAnalysisChanges}
                      isLoading={competitorsAnalysisSaving}
                      isSaved={competitorsAnalysisSaved}
                    />
                  </Grid>
                </Grid>
                {competitorsAnalysisLoading
                  ? [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        height={60}
                        sx={{ m: 1 }}
                      />
                    ))
                  : caseStudy.competitorsAnalysisList?.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <EditableReviewCard
                          key={index}
                          content={item.value}
                          isActive={item.isActive}
                          onChange={(e) => {
                            setCompetitorsAnalysisChanged(true);
                            onListItemChange(
                              caseStudy.competitorsAnalysisList,
                              "competitorsAnalysisList",
                              item.id,
                              e.target.value
                            );
                          }}
                          toggle={(value) => {
                            setCompetitorsAnalysisChanged(true);
                            toggleListItem(
                              caseStudy.competitorsAnalysisList,
                              "competitorsAnalysisList",
                              item.id,
                              value
                            );
                          }}
                        />
                      </Grid>
                    ))}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                <Grid
                  container
                  spacing={0}
                  justify="center"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Grid item>
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h6"
                      gutterBottom
                    >
                      Challenges
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={
                        waitingForProblems || caseStudy.problemsList?.length > 0
                      }
                      onClick={getProblems}
                      loading={problemsLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!problemsChanged}
                      handleClick={applyProblemChanges}
                      isLoading={problemsSaving}
                      isSaved={problemsSaved}
                    />
                  </Grid>
                </Grid>
                {problemsLoading
                  ? [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rounded"
                        height={60}
                        sx={{ m: 1 }}
                      />
                    ))
                  : caseStudy.problemsList?.map((problem, problemIndex) => (
                      <Grid item xs={12} key={problemIndex}>
                        <Paper
                          key={problemIndex}
                          variant="outlined"
                          sx={{ my: { xs: 1 }, p: { xs: 2 } }}
                        >
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <EditableReviewCardWithTitle
                                key={problemIndex}
                                title={problem.title}
                                isActive={problem.isActive}
                                onTitleChange={(e) => {
                                  setProblemsChanged(true);
                                  onProblemTitleChange(
                                    problemIndex,
                                    e.target.value
                                  );
                                }}
                                toggle={(value) => {
                                  setProblemsChanged(true);
                                  toggleProblemTitle(problemIndex, value);
                                }}
                              />
                            </Grid>
                          </Grid>
                          {problem.stories.map((story, storyIndex) => (
                            <>
                              <Grid item xs={12} key={storyIndex}>
                                <EditableReviewCard
                                  key={storyIndex}
                                  content={story.text}
                                  isActive={story.isActive}
                                  onChange={handleProblemStoryChange(
                                    problemIndex,
                                    storyIndex
                                  )}
                                  toggle={(value) =>
                                    toggleProblemStory(
                                      problemIndex,
                                      storyIndex,
                                      value
                                    )
                                  }
                                />
                              </Grid>
                            </>
                          ))}
                        </Paper>
                      </Grid>
                    ))}
              </Paper>
            </Grid>

            {bizValues.macros?.length > 0 && (
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                  <Grid
                    container
                    spacing={0}
                    justify="center"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="h6"
                        gutterBottom
                      >
                        Macro Environment Analysis
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Assistant
                        disabled={caseStudy.macroAnalysisList?.length > 0}
                        onClick={getMacroAnalysis}
                        loading={macroAnalysisLoading}
                      />
                      <SaveLoadingButton
                        isDisabled={!macroAnalysisChanged}
                        handleClick={applyMacroAnalysisChanges}
                        isLoading={macroAnalysisSaving}
                        isSaved={macroAnalysisSaved}
                      />
                    </Grid>
                  </Grid>
                  {macroAnalysisLoading
                    ? [...Array(3)].map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="rounded"
                          height={60}
                          sx={{ m: 1 }}
                        />
                      ))
                    : caseStudy.macroAnalysisList?.map((item, index) => (
                        <Grid item xs={12} key={index}>
                          <EditableReviewCard
                            key={index}
                            content={item.value}
                            isActive={item.isActive}
                            onChange={(e) => {
                              setMacroAnalysisChanged(true);
                              onListItemChange(
                                caseStudy.macroAnalysisList,
                                "macroAnalysisList",
                                item.id,
                                e.target.value
                              );
                            }}
                            toggle={(value) => {
                              setMacroAnalysisChanged(true);
                              toggleListItem(
                                caseStudy.macroAnalysisList,
                                "macroAnalysisList",
                                item.id,
                                value
                              );
                            }}
                          />
                        </Grid>
                      ))}
                </Paper>
              </Grid>
            )}

            {bizValues.micros?.length > 0 && (
              <Grid item xs={12}>
                <Paper variant="outlined" sx={{ my: { xs: 1 }, p: { xs: 2 } }}>
                  <Grid
                    container
                    spacing={0}
                    justify="center"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Grid item>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        variant="h6"
                        gutterBottom
                      >
                        Micro Environment Analysis
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Assistant
                        disabled={caseStudy.microAnalysisList?.length > 0}
                        onClick={getMicroAnalysis}
                        loading={microAnalysisLoading}
                      />
                      <SaveLoadingButton
                        isDisabled={!microAnalysisChanged}
                        handleClick={applyMicroAnalysisChanges}
                        isLoading={microAnalysisSaving}
                        isSaved={microAnalysisSaved}
                      />
                    </Grid>
                  </Grid>
                  {microAnalysisLoading
                    ? [...Array(3)].map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="rounded"
                          height={60}
                          sx={{ m: 1 }}
                        />
                      ))
                    : caseStudy.microAnalysisList?.map((item, index) => (
                        <Grid item xs={12} key={index}>
                          <EditableReviewCard
                            key={index}
                            content={item.value}
                            isActive={item.isActive}
                            onChange={(e) => {
                              setMicroAnalysisChanged(true);
                              onListItemChange(
                                caseStudy.microAnalysisList,
                                "microAnalysisList",
                                item.id,
                                e.target.value
                              );
                            }}
                            toggle={(value) => {
                              setMicroAnalysisChanged(true);
                              toggleListItem(
                                caseStudy.microAnalysisList,
                                "microAnalysisList",
                                item.id,
                                value
                              );
                            }}
                          />
                        </Grid>
                      ))}
                </Paper>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </Container>
  );
};

export default Review;
