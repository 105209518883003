import React, { useState } from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import FactoryIcon from "@mui/icons-material/Factory";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {
  Card,
  Avatar,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import IconWithText from "../../../../../components/IconWithText";
import { formatDate } from "../../../../../helper/DateHelper";
import { capitalize } from "../../../../../helper/TextHelper";
import { MuiButton } from "../../../../../components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DownloadIcon from "@mui/icons-material/Download";
import ArticleIcon from "@mui/icons-material/Article";
import TopicIcon from "@mui/icons-material/Topic";

export default function CaseStudyCard({
  refresh,
  caseStudy,
  handleEdit,
  handleView,
  handleDelete,
  handleTeachingNotes,
  createTeachingNote,
  handlePdf,
  handleAssignments,
}) {
  const [teachingNoteCreating, setTeachingNoteCreating] = useState(false);

  const handleTeachingNoteCreation = () => {
    setTeachingNoteCreating(true);
    createTeachingNote();
    setTimeout(() => {
      setTeachingNoteCreating(false);
      refresh();
    }, 15000);
  };
  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        action={
          <Tooltip
            title={
              caseStudy.numberOfAssignments > 0
                ? "Cannot delete, projects exists"
                : "Delete"
            }
          >
            <span>
              <IconButton
                color="secondary"
                onClick={handleDelete}
                disabled={caseStudy.numberOfAssignments > 0}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
        avatar={
          caseStudy.status === "ACTIVE" ? (
            <Avatar sx={{ bgcolor: "#008000" }}>
              <SpellcheckIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: "#9e9e9e" }}>
              {caseStudy.status?.charAt(0)}
            </Avatar>
          )
        }
        title={
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {caseStudy.title}
          </Typography>
        }
        subheader={"Created on: " + formatDate(caseStudy.createdAt)}
      />

      <CardContent>
        <IconWithText
          icon={<TopicIcon />}
          text={capitalize(caseStudy.concept)}
        />
        <IconWithText
          icon={<FactoryIcon />}
          text={capitalize(caseStudy.industry) + " Industry"}
        />
        <IconWithText
          icon={<StorefrontIcon />}
          text={caseStudy.marketType.toUpperCase() + " Market"}
        />
      </CardContent>

      <CardActions
        disableSpacing
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <MuiButton
            size="small"
            text={"Projects(" + caseStudy.numberOfAssignments + ")"}
            variant="text"
            onClick={handleAssignments}
          />
        </div>
        <div style={{ marginRight: "16px" }}>
          <Tooltip title={"Download Case Study"}>
            <IconButton color="primary" onClick={handlePdf} size="large">
              <PictureAsPdfIcon id="download-case-study" />
              <DownloadIcon
                sx={{
                  fontSize: 20,
                  position: "absolute",
                  bottom: -1,
                  right: -2,
                }}
              />
            </IconButton>
          </Tooltip>
          {caseStudy.teachingNoteId ? (
            <Tooltip title={"Download Teaching Note"}>
              <IconButton
                color="primary"
                onClick={handleTeachingNotes}
                size="large"
              >
                <ArticleIcon />
                <DownloadIcon
                  sx={{
                    fontSize: 20,
                    position: "absolute",
                    bottom: -1,
                    right: -2,
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={"Create Teaching Note"}>
              <IconButton
                disabled={teachingNoteCreating}
                color="success"
                onClick={() => handleTeachingNoteCreation()}
                size="large"
              >
                {teachingNoteCreating ? (
                  <CircularProgress size={24} color="success" />
                ) : (
                  <NoteAddIcon id="create-teaching-note" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </div>
        {/* <IconButton onClick={handleView}>
        import VisibilityIcon from "@mui/icons-material/Visibility";
          <VisibilityIcon />
        </IconButton> */}
        {/* <IconButton onClick={handleAssignment}>
          <PostAddIcon />
        </IconButton> */}

        {/* ) : null} */}
        {/* {note.userId && note.userId !== null ? ( */}
        {/* <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEdit(content)}
          >
            <EditOutlinedIcon />
          </IconButton> */}
        {/* ) : null} */}
        {/* <IconButton color="success" onClick={() => handleCasestudy(content.id)}>
          <AssignmentIcon />
        </IconButton> */}

        {/* <IconButton aria-label="edit" color="primary" onClick={handleEdit}>
          <EditOutlinedIcon />
        </IconButton> */}
      </CardActions>
    </Card>
  );
}
