import React, { useEffect, useState } from "react";
import { Fab, Zoom } from "@mui/material";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { useTheme } from "@mui/material/styles";
import Joyride, { STATUS } from "react-joyride";

const TourRide = ({ name, steps, stepActions }) => {
  const theme = useTheme();
  const [run, setRun] = useState(true);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fanoutFabStyle = {
    position: "fixed",
    bottom: 16,
    left: 16,
    // color: "common.white",
    // bgcolor: green[400],
    // "&:hover": {
    //   bgcolor: green[600],
    // },
  };

  useEffect(() => {
    const tourCompleted = localStorage.getItem(name);

    if (!tourCompleted) {
      setRun(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleJoyrideCallback = (data) => {
    const { index, status, type } = data;
    if (stepActions) {
      if (type === "tooltip") {
        const action = stepActions.find((action) => index === action.index);
        if (action && action.tooltip) {
          action.tooltip();
        }
      } else if (type === "step:before") {
        const action = stepActions.find((action) => index === action.index);
        if (action && action.before) {
          action.before();
        }
      } else if (type === "step:after") {
        const action = stepActions.find((action) => index === action.index);
        if (action && action.after) {
          action.after();
        }
      }
    }
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
      localStorage.setItem(name, "true"); // Save to localStorage
    }
  };

  const handleRestartTour = () => {
    localStorage.removeItem(name);
    setRun(true);
  };

  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        continuous
        showSkipButton
        styles={{
          tooltip: {
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          },
          tooltipContent: {
            padding: 0,
          },
          buttonNext: {
            // color: "#1976d2",
          },
          buttonBack: {
            color: "#1976d2",
          },
        }}
        callback={handleJoyrideCallback}
      />

      {!run && (
        <Zoom
          key={"fanoutFab-" + name}
          in={true}
          timeout={transitionDuration}
          style={{
            transitionDelay: `2000ms`,
          }}
          unmountOnExit
        >
          <Fab
            variant="extended"
            sx={fanoutFabStyle}
            color="secondary"
            onClick={() => handleRestartTour()}
          >
            <TipsAndUpdatesIcon sx={{ mr: 0 }} />
          </Fab>
        </Zoom>
      )}
    </>
  );
};

export default TourRide;
