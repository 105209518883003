import React from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NumbersIcon from "@mui/icons-material/Numbers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Avatar,
  Tooltip,
  IconButton,
  CardHeader,
  CardContent,
  CardActions,
  Card,
} from "@mui/material";
import { MuiButton } from "../../../../../components";
import IconWithText from "../../../../../components/IconWithText";

export default function CourseCard({
  course,
  handleEdit,
  handleDelete,
  handleCasestudy,
}) {
  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#9e9e9e" }}>
            {course.field?.toUpperCase().charAt(0)}
          </Avatar>
        }
        action={
          <IconButton aria-label="edit" color="primary" onClick={handleEdit}>
            <EditOutlinedIcon />
          </IconButton>
        }
        title={course.name}
        subheader={course.studentLevel}
      />

      <CardContent>
        <>
          {/* <IconWithText
            icon={<MenuBookIcon />}
            text={course.textBook || "No TextBook"}
          /> */}
          <IconWithText icon={<NumbersIcon />} text={course.code} />
          <IconWithText
            icon={<CalendarMonthIcon />}
            text={course.semester + " " + course.startDate[0]}
          />
        </>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <MuiButton
          size="small"
          text={"Case studies(" + course.numberOfCases + ")"}
          variant="text"
          onClick={handleCasestudy}
        />
        <Tooltip
          title={
            course.numberOfCases > 0
              ? "Cannot delete, case study exists"
              : "Delete"
          }
        >
          <span>
            <IconButton
              color="secondary"
              onClick={() => handleDelete(course.id)}
              disabled={course.numberOfCases > 0}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
