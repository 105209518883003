import { httpClient } from "./RestService";

export const types = async () => {
  return await httpClient().get("/v1/education/cs/assmnt/tps");
};

export const getAllAssignments = async (caseStudyId) => {
  return await httpClient().get("/v1/education/cs/assmnt/list/" + caseStudyId);
};

export const getAllQuestions = async (caseStudyId) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/qs/crt/" + caseStudyId
  );
};

export const create = async (request) => {
  return await httpClient().post("/v1/education/cs/assmnt", request);
};

export const update = async (request) => {
  return await httpClient().put("/v1/education/cs/assmnt", request);
};

export const del = async (id) => {
  return await httpClient().delete("/v1/education/uinst/" + id);
};

export const deleletAssignment = async (id) => {
  return await httpClient().delete("/v1/education/cs/assmnt/" + id);
};

export const deleteQuestion = async (id) => {
  return await httpClient().delete(
    "/v1/education/instructor/casestudy/qs/" + id
  );
};

export const addNewQuestion = async (caseStudyId, question) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/qs/create",
    {
      caseStudyId,
      question,
    }
  );
};
