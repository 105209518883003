import React from "react";
import Typography from "@mui/material/Typography";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import {
  Stack,
  Grid,
  Container,
  Box,
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  Slider,
} from "@mui/material";

// Copied from https://github.com/mui/material-ui/blob/v5.16.7/docs/data/material/getting-started/templates/landing-page/components/Features.js

export default function Promotion({
  caseStudyPrice,
  amount,
  onChange,
  percentage,
  applyPromotion,
  error,
}) {
  const items = [
    {
      icon: <MoneyOffIcon />,
      title: "Promotion",
      description: `You are eligible to give ${percentage}% discount to the students on this project! Please use the slider below to set the percentage.`,
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }

  return (
    <Container id="promotion" sx={{ py: { xs: 4 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            // useFlexGap
            sx={{ width: "100%", display: { sm: "flex" } }}
            // sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                sx={{
                  p: 3,
                  height: "fit-content",
                  //   width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box>{icon}</Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    {/* <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        "& > svg": { transition: "0.2s" },
                        "&:hover > svg": { transform: "translateX(2px)" },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: "1px", ml: "2px" }}
                      />
                    </Link> */}
                    <Slider
                      sx={{ mt: 5 }}
                      size="small"
                      value={amount || 0}
                      name="discount"
                      onChange={(e) => onChange(e)}
                      aria-labelledby="input-slider"
                      marks
                      step={1}
                      min={0}
                      max={percentage}
                      valueLabelDisplay="on"
                      getAriaValueText={valuetext}
                    />
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          size={{ xs: 12, sm: 5, lg: 4 }}
          sx={{ p: 2 }}
          // sx={{
          //   p: 2,
          //   display: { xs: "flex" },
          //   flexDirection: "column",
          //   backgroundColor: "background.paper",
          //   borderRight: { sm: "none", md: "1px solid" },
          //   borderColor: { sm: "none", md: "divider" },
          //   alignItems: "start",
          //   pt: 16,
          //   px: 10,
          //   gap: 2,
          // }}
        >
          <Box
            sx={{
              border: error && "1px solid red",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100%",
              maxWidth: 500,
              p: 2,
            }}
          >
            <React.Fragment>
              <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                Price
              </Typography>
              <Typography variant="h4" gutterBottom>
                {"$" + applyPromotion(amount)}
              </Typography>
              {error && (
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "medium", color: "red" }}
                >
                  {error}
                </Typography>
              )}
              <List disablePadding>
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText sx={{ mr: 2 }} primary={"Promotion"} />
                  <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    {amount + "%"}
                  </Typography>
                </ListItem>

                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText
                    sx={{ mr: 2 }}
                    primary={"Initial Price"}
                    secondary={
                      "The initial price students will pay before applying any promotions."
                    }
                  />
                  <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                    {"$" + caseStudyPrice}
                  </Typography>
                </ListItem>
              </List>
            </React.Fragment>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
