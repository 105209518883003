import React from "react";
import { Card, CardContent, Grow, Typography } from "@mui/material";

const TourStep = ({ title, description }) => {
  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h6">{title}</Typography>
          <Typography>{description}</Typography>
        </CardContent>
      </Card>
    </Grow>
  );
};

export default TourStep;
