import { courseFields } from ".";
import { isEpmty } from "../../../../../helper/TextHelper";
import { formatDate } from "../../../../../helper/DateHelper";
import isValid from "date-fns/isValid";
import isAfter from "date-fns/isAfter";

export const validate = (values) => {
  const errors = {};
  const data = values;
  courseFields.forEach(({ name, required }) => {
    if (required && isEpmty(data[name])) {
      errors[name] = "This field is required.";
    }
  });

  const startDate = values["startDate"];
  const endDate = values["endDate"];

  if (startDate && !isValid(formatToDate(startDate))) {
    errors["startDate"] = "Date is not valid.";
  }

  if (endDate) {
    if (!isValid(formatToDate(endDate))) {
      errors["endDate"] = "Date is not valid.";
    } else if (
      startDate &&
      !isAfter(formatToDate(endDate), formatToDate(startDate))
    ) {
      errors["endDate"] = "End date must be greater than start date.";
    }
  }

  // Add errors to data object
  data.errors = errors;

  // You can add more validation checks here based on your requirements

  return Object.keys(errors).length === 0;
};

export const formatDates = (values) => {
  if (values["startDate"]) {
    values["startDate"] = formatDate(values["startDate"]);
  }
  if (values["endDate"]) {
    values["endDate"] = formatDate(values["endDate"]);
  }
};

// in edit mode the date values are in format: [2020, 11, 11]
// Convert array format [2024, 11, 30] to Date format
const formatToDate = (dateArray) => {
  if (
    Array.isArray(dateArray) &&
    dateArray.length === 3 &&
    dateArray.every((item) => Number.isInteger(item))
  ) {
    const [year, month, day] = dateArray;
    return new Date(year, month - 1, day);
  }
  return dateArray; // Assume already in Date format if not an array
};
