import React from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { upperCaseFirstLetter } from "../../../../helper/TextHelper";
import { MuiButton } from "../../../../components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import { styled } from "@mui/material/styles";

export default function AssignmentCard({
  assignment,
  handleNotification,
  handleDelete,
  handleStudents,
  handleEdit,
}) {
  const copyToClipboard = (text, type) => {
    // Use navigator clipboard API if it's supported
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      handleNotification(type);
    } else {
      // Fallback: create a temporary textarea to copy the text
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      handleNotification(type);
    }
  };

  const btnText = (assignment) => {
    // return "GROUP" === assignment.type
    //   ? "Groups"
    //   : "Students(" + assignment.numberOfStudents + ")";
    return "Students(" + assignment.numberOfStudents + ")";
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const htmlTooltipTitle =
    "Click to copy. Then you need to share it with students.";

  return (
    <Card elevation={1} sx={{ m: 0.5 }}>
      <CardHeader
        avatar={
          assignment.type === "INDIVIDUAL" ? (
            <Avatar sx={{ bgcolor: "#008000" }}>
              <PersonIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: "#008000" }}>
              <GroupsIcon />
            </Avatar>
          )
        }
        title={
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {upperCaseFirstLetter(assignment.title)}
          </Typography>
        }
        action={
          <IconButton aria-label="edit" color="primary" onClick={handleEdit}>
            <EditOutlinedIcon />
          </IconButton>
        }
        subheader={
          <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
            <Typography variant="body2" color="text.secondary">
              {"Due date: " + assignment.dueDate}
            </Typography>
          </Box>
        }
      />

      <CardContent>
        <List sx={{ width: "100%", bgcolor: "background.paper" }} dense={true}>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{htmlTooltipTitle}</Typography>
              </React.Fragment>
            }
          >
            <CardActionArea
              onClick={() => copyToClipboard(assignment.id, "Case study")}
            >
              <ListItem
                alignItems="flex-start"
                // secondaryAction={
                //   <IconButton edge="end" aria-label="copy" color="primary">
                //     <ContentCopyIcon />
                //   </IconButton>
                // }
              >
                <ListItemText
                  primary={
                    <Typography variant="body2" color={"textSecondary"}>
                      {"Case study code"}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        fontWeight="fontWeightMedium"
                        component="span"
                        variant="body2"
                        sx={{
                          color: "text.primary",
                          display: "inline",
                          // textDecoration: "underline",
                        }}
                      >
                        {assignment.id}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </CardActionArea>
          </HtmlTooltip>

          {assignment.promoCode && (
            <>
              <Divider variant="inset" component="li" />
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">{htmlTooltipTitle}</Typography>
                  </React.Fragment>
                }
              >
                <CardActionArea
                  onClick={() =>
                    copyToClipboard(assignment.promoCode, "Promotion")
                  }
                >
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary=<Typography
                        variant="body2"
                        color={"textSecondary"}
                      >
                        {"Promotion code"}
                      </Typography>
                      secondary={
                        <React.Fragment>
                          <Typography
                            fontWeight="fontWeightMedium"
                            component="span"
                            variant="body2"
                            sx={{
                              color: "text.primary",
                              display: "inline",
                              // textDecoration: "underline",
                            }}
                          >
                            {assignment.promoCode}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </CardActionArea>
              </HtmlTooltip>
            </>
          )}
        </List>
      </CardContent>

      <CardActions
        disableSpacing
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <MuiButton
          size="small"
          text={btnText(assignment)}
          variant="text"
          onClick={handleStudents}
        />
        <Tooltip
          title={
            assignment.numberOfStudents > 0
              ? "Cannot delete, students exists"
              : "Delete"
          }
        >
          <span>
            <IconButton
              color="secondary"
              onClick={handleDelete}
              disabled={assignment.numberOfStudents > 0}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
