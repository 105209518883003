import React, { useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Skeleton,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import * as casestudyService from "../../services/CasestudyService";
import Notification from "../../../../components/mui/Notification";
import EditableReviewCard from "./EditableReviewCard";
import SaveLoadingButton from "../../../../components/SaveLoadingButton";
import {
  Assistant,
  InfoTooltip,
  TourRide,
  TourStep,
} from "../../../../components";

const Questions = ({ course, formValues }) => {
  const [questions, setQuestions] = useState([]);
  const companyValues = formValues.company;
  const caseStudyId = formValues.caseStudyId;
  const concept = formValues.concept;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [questionsSaving, setQuestionsSaving] = useState();
  const [questionsSaved, setQuestionsSaved] = useState();
  const [questionsChanged, setQuestionsChanged] = useState();
  const [questionsLoading, setQuestionsLoading] = useState(false);

  const onListItemChange = (id, value) => {
    const updatedList = questions.map((item) =>
      item.id === id ? { ...item, value } : item
    );
    setQuestions(updatedList);
  };

  const toggleListItem = (id, value) => {
    const updatedList = questions.map((item) =>
      item.id === id ? { ...item, isActive: value } : item
    );
    setQuestions(updatedList);
  };

  const applyQuestionChanges = () => {
    setQuestionsSaving(true);
    const request = {
      caseStudyId: formValues.caseStudyId,
      list: questions,
    };
    casestudyService
      .updateQuestions(request)
      .then((ignored) => setQuestionsChanged(false))
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not update questions. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setQuestionsSaving(false);
          setQuestionsSaved(true);
        }, 1000);
        setTimeout(() => {
          setQuestionsSaved(false);
        }, 4000);
      });
  };

  const setCaseStudyQuestions = (list) => {
    const newList = list.map((item, index) => ({
      id: item.id,
      value: item.text,
      isActive: true,
    }));
    setQuestions(newList);
  };

  const getQuestions = () => {
    setQuestionsLoading(true);
    const request = {
      caseStudyId: caseStudyId,
      concept: concept,
      studentLevel: course.studentLevel,
      field: course.field,
      companyName: companyValues.names[0],
    };

    casestudyService
      .getQuestions(request)
      .then((res) => {
        setCaseStudyQuestions(res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch questions. Please try again later",
          type: "error",
        });
      })
      .finally(() => setQuestionsLoading(false));
  };

  const tourSteps = [
    {
      target: "#questions-page-step-1",
      content: (
        <TourStep
          title={"Add Question"}
          description={
            "Click BCL Assistant to add question. You can add, edit or delete question. Remember to save your changes once you're done editing."
          }
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <TourRide name="case-study-creation-questions-page" steps={tourSteps} />
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Important</AlertTitle>
        In the last step, you can add question(s) at the end of the case study.
        If you don't want to add any questions, you can skip this step and click
        "Submit."
      </Alert>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}
              >
                <Grid container spacing={0}>
                  <Grid item position={"relative"}>
                    <Typography
                      id="questions-page-step-1"
                      sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
                      variant="h6"
                      gutterBottom
                    >
                      Questions
                    </Typography>
                    <InfoTooltip
                      title={
                        "Click BCL Assistant to add question. You can add, edit or delete question. Remember to save your changes once you're done editing."
                      }
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: "auto" }}>
                    <Assistant
                      disabled={questions.length > 0}
                      onClick={getQuestions}
                      loading={questionsLoading}
                    />
                    <SaveLoadingButton
                      isDisabled={!questionsChanged}
                      handleClick={applyQuestionChanges}
                      isLoading={questionsSaving}
                      isSaved={questionsSaved}
                    />
                  </Grid>
                </Grid>
                {questionsLoading ? (
                  <>
                    <Skeleton variant="rounded" height={60} />
                    <br />
                    <Skeleton variant="rounded" height={60} />{" "}
                  </>
                ) : (
                  questions.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <EditableReviewCard
                        key={index}
                        content={item.value}
                        isActive={item.isActive}
                        onChange={(e) => {
                          setQuestionsChanged(true);
                          onListItemChange(item.id, e.target.value);
                        }}
                        toggle={(value) => {
                          setQuestionsChanged(true);
                          toggleListItem(item.id, value);
                        }}
                      />
                    </Grid>
                  ))
                )}
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </Container>
  );
};

export default Questions;
