export const toLowerCaseExceptFirstLetter = (text) =>
  text.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );

export const upperCaseFirstLetter = (text) =>
  `${text.slice(0, 1).toUpperCase()}${text.slice(1)}`;

export const isEpmty = (text) =>
  !text ||
  text.length === 0 ||
  text === null ||
  (typeof text === "string" ? text.trim() === "" : text === "");

export const capitalize = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export const splitByUnderscoreAndCapitalize = (str) => {
  // Split the string by underscores
  let words = str.split("_");

  // Capitalize the first letter of each word and make the rest lowercase
  for (let i = 0; i < words.length; i++) {
    words[i] =
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  // Join the words with spaces
  return words.join(" ");
};
