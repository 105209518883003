import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import * as casestudyService from "../../services/CasestudyService";
import Notification from "../../../../components/mui/Notification";
import { MuiChipsInput } from "mui-chips-input";
import { isEpmty } from "../../../../helper/TextHelper";
import { AnimatedCard } from "./AnimatedCard";
import {
  Assistant,
  ChipsEditMessage,
  InfoTooltip,
} from "../../../../components";

const BusinessDevelopment = ({
  course,
  formValues,
  onInputChange,
  updateValues,
}) => {
  const bizValues = formValues.biz;
  const baseInfoValues = formValues.baseInfo;
  const companyValues = formValues.company;
  const topicsValues = formValues.topics;
  const [macrosLoading, setMacrosLoading] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getFactors = () => {
    setMacrosLoading(true);
    const request = {
      caseStudyId: caseStudyId,
      field: course.field,
      topics: topicsValues.list?.join(", "),
      companyName: companyValues.names[0],
      chapterTitle: baseInfoValues.chapters[0],
    };
    casestudyService
      .getBizFactors(request)
      .then((res) => {
        const macroList = res.data.macros;
        const microList = res.data.micros;
        updateValues("macrosChips", macroList);
        updateValues("macrosHiddenChips", macroList);
        updateValues("microsChips", microList);
        updateValues("microsHiddenChips", microList);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch factors. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setMacrosLoading(false);
      });
  };

  const onMacroChipsClick = (card) => {
    updateValues("macros", [...bizValues.macros, card.title]);

    const filteredCards = bizValues.macrosHiddenChips.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("macrosHiddenChips", filteredCards);

    if (bizValues.errors) {
      updateValues(
        "errors",
        Object.assign({}, bizValues.errors, delete bizValues.errors.macros)
      );
    }
  };

  const onMacroChipsChange = (newChips) => {
    updateValues("macros", newChips);
  };

  const onMacroChipsDelete = (chipValue, chipIndex) => {
    const filteredChips = bizValues.macros.filter((chip) => chip !== chipValue);
    onMacroChipsChange(filteredChips);

    if (bizValues.macrosChips?.some((card) => card.title === chipValue)) {
      // Check if the chip is not already in hiddenChips
      if (
        !bizValues.macrosHiddenChips.some(
          (hiddenCard) => hiddenCard.title === chipValue
        )
      ) {
        bizValues.macrosHiddenChips.push(
          bizValues.macrosChips.find((card) => card.title === chipValue)
        );
      }
    }
  };

  const onMicroChipsClick = (card) => {
    updateValues("micros", [...bizValues.micros, card.title]);

    const filteredCards = bizValues.microsHiddenChips.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("microsHiddenChips", filteredCards);

    if (bizValues.errors) {
      updateValues(
        "errors",
        Object.assign({}, bizValues.errors, delete bizValues.errors.micros)
      );
    }
  };

  const onMicroChipsChange = (newChips) => {
    updateValues("micros", newChips);
  };

  const onMicroChipsDelete = (chipValue, chipIndex) => {
    const filteredChips = bizValues.micros.filter((chip) => chip !== chipValue);
    onMicroChipsChange(filteredChips);

    if (bizValues.microsChips?.some((card) => card.title === chipValue)) {
      // Check if the chip is not already in hiddenChips
      if (
        !bizValues.microsHiddenChips.some(
          (hiddenCard) => hiddenCard.title === chipValue
        )
      ) {
        bizValues.microsHiddenChips.push(
          bizValues.microsChips.find((card) => card.title === chipValue)
        );
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Important</AlertTitle>
        In this step, add macro and micro environment analysis factor. They are
        not required fields.
      </Alert>
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item position={"relative"}>
            <Typography
              sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
              variant="h6"
              gutterBottom
            >
              Macro Environment
            </Typography>
            <InfoTooltip
              title={
                "The factors will be used in macro environment analysis. Select up to three variables."
              }
            />
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <Assistant
              disabled={bizValues.macrosChips?.length > 0}
              onClick={getFactors}
              loading={macrosLoading}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              hideClearAll
              addOnBlur
              value={bizValues.macros}
              onDeleteChip={onMacroChipsDelete}
              onChange={onMacroChipsChange}
              error={
                isEpmty(bizValues.macros) && Boolean(bizValues.errors?.macros)
              }
              placeholder="Use Assistant or enter macro environment factors"
              helperText={
                bizValues.errors?.macros ? (
                  bizValues.errors?.macros
                ) : isEpmty(bizValues.macros) ? (
                  ""
                ) : (
                  <ChipsEditMessage />
                )
              }
            />
          </Grid>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {bizValues.macrosHiddenChips?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={onMacroChipsClick}
              />
            ))}
          </div>
        </Grid>
      </Paper>

      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item position={"relative"}>
            <Typography
              sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
              variant="h6"
              gutterBottom
            >
              Micro Environment
            </Typography>
            <InfoTooltip
              title={
                "The factors will be used in Micro environment analysis. Select up to three variables."
              }
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              hideClearAll
              addOnBlur
              value={bizValues.micros}
              onDeleteChip={onMicroChipsDelete}
              onChange={onMicroChipsChange}
              error={
                isEpmty(bizValues.micros) && Boolean(bizValues.errors?.micros)
              }
              placeholder="Use Assistant or enter micro environment factors"
              helperText={
                bizValues.errors?.micros ? (
                  bizValues.errors?.micros
                ) : isEpmty(bizValues.micros) ? (
                  ""
                ) : (
                  <ChipsEditMessage />
                )
              }
            />
          </Grid>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {bizValues.microsHiddenChips?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={onMicroChipsClick}
              />
            ))}
          </div>
        </Grid>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </Container>
  );
};

export default BusinessDevelopment;
