import { INSTRUCTOR_BOARD_ROUTE } from "../../helper/Constants";

const cards = [
  {
    title: "Get started",
    description: "Looking for an easy way to create and share case studies?",
    imageUrl: "/resources/cs-landing.png",
    route: INSTRUCTOR_BOARD_ROUTE,
    time: 1500,
    buttonText: "Get Started",
    buttonVariant: "contained",
  },
];

export default cards;
