import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import * as casestudyService from "../../services/CasestudyService";
import { AnimatedCard } from "./AnimatedCard";
import Notification from "../../../../components/mui/Notification";
import { isEpmty } from "../../../../helper/TextHelper";
import {
  Assistant,
  ChipsEditMessage,
  InfoTooltip,
  TourRide,
  TourStep,
} from "../../../../components";

const CaseSubTopics = ({ course, formValues, updateValues }) => {
  const subTopicsValues = formValues.subTopics;
  const topicsValues = formValues.topics;
  const companyValues = formValues.company;
  const [subTopicsLoading, setSubTopicsLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getSubTopics = () => {
    setSubTopicsLoading(true);
    const request = {
      caseStudyId: caseStudyId,
      field: course.field,
      topics: topicsValues.list?.join(", "),
      companyName: companyValues.names[0],
      studentLevel: course.studentLevel,
      marketTerritory: companyValues.territory,
    };
    casestudyService
      .getSubTopics(request)
      .then((res) => {
        updateValues("subTopicsCards", res.data);
        updateValues("subTopicsHiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch sub-topics. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setSubTopicsLoading(false);
      });
  };

  const handleChips = (newChips) => {
    updateValues("list", newChips);
  };

  const handleCardClick = (card) => {
    updateValues("list", [...subTopicsValues.list, card.title]);

    const filteredCards = subTopicsValues.subTopicsHiddenCards.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("subTopicsHiddenCards", filteredCards);

    if (subTopicsValues.errors) {
      updateValues(
        "errors",
        Object.assign(
          {},
          subTopicsValues.errors,
          delete subTopicsValues.errors.list
        )
      );
    }
  };

  const handleDeleteChip = (chipValue, chipIndex) => {
    const filteredChips = subTopicsValues.list.filter(
      (chip) => chip !== chipValue
    );
    updateValues("list", filteredChips);

    if (
      subTopicsValues.subTopicsCards?.some((card) => card.title === chipValue)
    ) {
      // Check if the card is not already in hiddenCards
      if (
        !subTopicsValues.subTopicsHiddenCards.some(
          (hiddenCard) => hiddenCard.title === chipValue
        )
      ) {
        subTopicsValues.subTopicsHiddenCards.push(
          subTopicsValues.subTopicsCards.find(
            (card) => card.title === chipValue
          )
        );
      }
    }
  };

  const tourSteps = [
    {
      target: "#subtopic-page-step-1",
      content: (
        <TourStep
          title={"Add Subtopics"}
          description={
            "Add TWO main subtopics. They should be related to your main topic. Arrange subtopics in order. The first subtopic should address the primary or initial challenge, as it will be explained first during the challenge clarification. The second subtopic could be a subsequent challenge or second challenge."
          }
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <TourRide name="case-study-creation-subtopic-page" steps={tourSteps} />
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Important</AlertTitle>
        In this step, break the main challenge into small challenges under main
        topic.
      </Alert>
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item position={"relative"}>
            <Typography
              id="subtopic-page-step-1"
              sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
              variant="h6"
              gutterBottom
            >
              Subtopics
            </Typography>
            <InfoTooltip
              title={
                "Use BCL Assistant or enter manually. You should add TWO main subtopics. They should be related to your main topic. Arrange subtopics in order. The first subtopic should address the primary or initial challenge, as it will be explained first during the challenge clarification. The second subtopic could be a subsequent challenge or second challenge."
              }
            />
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Assistant
              disabled={subTopicsValues.subTopicsCards?.length > 0}
              onClick={getSubTopics}
              loading={subTopicsLoading}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              hideClearAll
              addOnBlur
              value={subTopicsValues.list}
              onDeleteChip={handleDeleteChip}
              onChange={handleChips}
              error={
                isEpmty(subTopicsValues.list) &&
                Boolean(subTopicsValues.errors?.list)
              }
              helperText={
                subTopicsValues.errors?.list ? (
                  subTopicsValues.errors?.list
                ) : isEpmty(subTopicsValues.list) ? (
                  ""
                ) : (
                  <ChipsEditMessage />
                )
              }
              placeholder="Use Assistant or enter subtopics"
            />
          </Grid>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {subTopicsValues.subTopicsHiddenCards?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        </Grid>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </Container>
  );
};

export default CaseSubTopics;
