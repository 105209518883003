import React from "react";

function ChipsEditMessage() {
  return (
    <div>
      <style>
        {`
          @keyframes slideIn {
            0% {
              opacity: 0;
              transform: translateX(-100%);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }
        `}
      </style>

      <span
        style={{
          fontSize: "0.9rem",
          color: "#e68422",
          position: "relative",
          animation: "slideIn 1s ease-out forwards",
        }}
      >
        {"(Double click to edit)"}
      </span>
    </div>
  );
}

export default ChipsEditMessage;
