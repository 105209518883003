import React from "react";
import { LoadingButton } from "@mui/lab";
import AssistantIcon from "@mui/icons-material/AutoAwesome";

const Assistant = ({ disabled, onClick, loading }) => {
  return (
    <LoadingButton
      id="bcl-assistant"
      disabled={disabled}
      sx={{ mr: 1 }}
      size="small"
      onClick={onClick}
      loading={loading}
      variant="outlined"
      startIcon={<AssistantIcon />}
      loadingPosition="start"
    >
      <span>BCL Assistant</span>
    </LoadingButton>
  );
};

export default Assistant;
