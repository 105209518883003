import * as React from "react";
import { styled } from "@mui/material/styles";
import { Stack, Switch, Typography } from "@mui/material";

const IOSSwitch = styled((props) => (
  <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
    <Typography>Off</Typography>
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
    <Typography>On</Typography>
  </Stack>
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        // ...theme.applyStyles('dark', {
        //   backgroundColor: '#2ECA45',
        // }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      // ...theme.applyStyles('dark', {
      //   color: theme.palette.grey[600],
      // }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      // ...theme.applyStyles('dark', {
      //   opacity: 0.3,
      // }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    // ...theme.applyStyles('dark', {
    //   backgroundColor: '#39393D',
    // }),
  },
}));

export default IOSSwitch;
