import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { AnimatedCard } from "./AnimatedCard";
import {
  Assistant,
  ChipsEditMessage,
  InfoTooltip,
  MuiInput,
  TourRide,
  TourStep,
} from "../../../../components";
import * as casestudyService from "../../services/CasestudyService";
import { generateCompanyCards } from "./compny";
import Notification from "../../../../components/mui/Notification";
import { isEpmty } from "../../../../helper/TextHelper";

const Company = ({ formValues, onInputChange, updateValues }) => {
  const baseInfoValues = formValues.baseInfo;
  const companyValues = formValues.company;
  const industryValues = formValues.industry;
  const [namesLoading, setNamesLoading] = useState();
  const [competitorsLoading, setCompetitorsLoading] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getNames = () => {
    setNamesLoading(true);
    casestudyService
      .getCompanyNames(
        caseStudyId,
        industryValues.types[0],
        industryValues.country,
        industryValues.province,
        baseInfoValues.marketType
      )
      .then((res) => {
        const companyCards = generateCompanyCards(res.data);
        updateValues("cards", companyCards);
        updateValues("hiddenCards", companyCards);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch company names. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setNamesLoading(false);
      });
  };

  const handleCardsChange = (newChips) => {
    if (newChips.length > 0) {
      const selectedCard = newChips[newChips.length - 1];
      updateValues("names", [selectedCard]);
      if (companyValues.cards?.some((card) => card.title !== selectedCard)) {
        // seems user entered a value manually, reseting the hiddenCards
        updateValues("hiddenCards", companyValues.cards);
      }
    }
  };

  const handleCardClick = (card) => {
    const filteredCards = companyValues.cards.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("names", [card.title]);
    updateValues("hiddenCards", filteredCards);
    if (companyValues.errors) {
      const targetObject = Object.assign({}, companyValues.errors);
      targetObject.type = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleDeleteCard = (chipValue, chipIndex) => {
    updateValues("names", []);

    // Check if chipValue exists in cards and not already in hiddenCards
    const existingCard = companyValues.cards.find(
      (card) => card.title === chipValue
    );

    const isCardHidden = companyValues.hiddenCards.some(
      (hiddenCard) => hiddenCard.title === chipValue
    );

    if (existingCard && !isCardHidden) {
      companyValues.hiddenCards.push(existingCard);
    }
  };

  const getCompetitors = () => {
    setCompetitorsLoading(true);
    casestudyService
      .getCompetitos(
        caseStudyId,
        companyValues.names[0],
        companyValues.territory,
        industryValues.province,
        baseInfoValues.chapters[0],
        baseInfoValues.chapterTopics?.join(", ")
      )
      .then((res) => {
        updateValues("competitorsChips", res.data);
        updateValues("competitorsHiddenChips", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch competitors. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setCompetitorsLoading(false);
      });
  };

  const onCompetitorChipsClick = (card) => {
    updateValues("competitors", [...companyValues.competitors, card.title]);

    const filteredCards = companyValues.competitorsHiddenChips.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("competitorsHiddenChips", filteredCards);

    if (companyValues.errors) {
      const targetObject = Object.assign({}, companyValues.errors);
      targetObject.competitors = undefined;
      updateValues("errors", targetObject);
    }
  };

  const onCompetitorChipsChange = (newChips) => {
    updateValues("competitors", newChips);
  };

  const onCompetitorChipsDelete = (chipValue, chipIndex) => {
    const filteredChips = companyValues.competitors.filter(
      (chip) => chip !== chipValue
    );
    onCompetitorChipsChange(filteredChips);

    if (
      companyValues.competitorsChips?.some((card) => card.title === chipValue)
    ) {
      // Check if the chip is not already in hiddenChips
      if (
        !companyValues.competitorsHiddenChips.some(
          (hiddenCard) => hiddenCard.title === chipValue
        )
      ) {
        companyValues.competitorsHiddenChips.push(
          companyValues.competitorsChips.find(
            (card) => card.title === chipValue
          )
        );
      }
    }
  };

  const tourSteps = [
    {
      target: "#company-page-step-1",
      content: (
        <TourStep
          title={"Add Market"}
          description={
            "Market territory refers to the geographic context where the case study takes place, and it may differ from the company's home country. You can also select the same country as in previous step if it applies to your case study."
          }
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <TourRide name="case-study-creation-company-page" steps={tourSteps} />
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Important</AlertTitle>
        In this step, choose the company for your case study, specify its market
        territory, and list the names of its competitors. The case study will
        focus on the selected company. The market territory refers to the
        country where the case study takes place.
      </Alert>
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item position={"relative"}>
            <Typography
              sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
              variant="h6"
              gutterBottom
            >
              Company Name
            </Typography>
            <InfoTooltip
              title={
                "Use BCL Assistant or enter company name. Only ONE company can be added."
              }
            />
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Assistant
              disabled={companyValues.cards?.length > 0}
              onClick={getNames}
              loading={namesLoading}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              hideClearAll
              addOnBlur
              value={companyValues.names}
              onDeleteChip={handleDeleteCard}
              onChange={handleCardsChange}
              error={
                isEpmty(companyValues.names) &&
                Boolean(companyValues.errors?.names)
              }
              helperText={
                isEpmty(companyValues.names) ? "" : <ChipsEditMessage />
              }
              placeholder="Use Assistant or enter company name"
            />
          </Grid>
        </Grid>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {companyValues.hiddenCards?.map((card, index) => (
            <AnimatedCard
              key={index}
              card={card}
              index={index}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      </Paper>

      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <React.Fragment>
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Grid item position={"relative"}>
              <Typography
                id="company-page-step-1"
                sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
                variant="h6"
                gutterBottom
              >
                Territory
              </Typography>
              <InfoTooltip
                title={
                  "Market territory refers to the geographic context where the case study takes place. For example, if you previously selected the USA and Walmart, but you want to focus on Walmart’s operations in Canada, you should choose Canada as the market territory. You can also select the same country as in previous steps if it applies to your case study."
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6}>
              <Autocomplete
                sx={{ width: 300 }}
                disablePortal
                id="combo-box-territory"
                value={
                  companyValues.territory === ""
                    ? null
                    : companyValues.territory
                }
                options={targetMarketOptions}
                onChange={(e, newValue) => updateValues("territory", newValue)}
                renderInput={(params) => (
                  <MuiInput
                    {...params}
                    required
                    error={companyValues.errors?.territory}
                    id="territory"
                    name="territory"
                    label="Market Territory"
                    fullWidth
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <MuiInput
                required
                error={companyValues.errors?.territory}
                id="territory"
                name="territory"
                label="Market Territory"
                value={companyValues.territory || ""}
                fullWidth
                variant="standard"
                onChange={(e) => onInputChange(e)}
                helperText="Country Name (e.g., Canada, USA)"
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>

      {companyValues.names.length > 0 && !isEpmty(companyValues.territory) && (
        <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
          <Grid
            container
            spacing={0}
            justify="center"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Grid item position={"relative"}>
              <Typography
                sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
                variant="h6"
                gutterBottom
              >
                Competitors
              </Typography>
              <InfoTooltip title={"You can add up to 3 competitors."} />
            </Grid>
            <Grid item style={{ marginLeft: "auto" }}>
              <Assistant
                disabled={companyValues.competitorsChips?.length > 0}
                onClick={getCompetitors}
                loading={competitorsLoading}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <MuiChipsInput
                style={{ width: "95%" }}
                hideClearAll
                addOnBlur
                value={companyValues.competitors}
                onDeleteChip={onCompetitorChipsDelete}
                onChange={onCompetitorChipsChange}
                error={
                  isEpmty(companyValues.competitors) &&
                  Boolean(companyValues.errors?.competitors)
                }
                placeholder="Use Assistant or enter competitor names"
                helperText={
                  isEpmty(companyValues.competitors) ? "" : <ChipsEditMessage />
                }
              />
            </Grid>
          </Grid>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {companyValues.competitorsHiddenChips?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={onCompetitorChipsClick}
              />
            ))}
          </div>
        </Paper>
      )}

      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </Container>
  );
};

export default Company;
