import React, { useState } from "react";
import { Grid, Button, Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container, Paper } from "@mui/material";
import SyncLockOutlinedIcon from "@mui/icons-material/SyncLockOutlined";
import { Form, useForm } from "../../../components/mui/useForm";
import { MuiInput } from "../../../components";
import LoadingButton from "@mui/lab/LoadingButton";
import * as userService from "../services/UserService";
import Notification from "../../../components/mui/Notification";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../helper/Constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    margin: "20px auto",
  },
  btn: {
    margin: "8px 0",
  },
}));

const initialFValues = {
  username: "",
};

export default function ForgotPassword(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("username" in fieldValues)
      temp.username =
        fieldValues.username !== "" && /$^|.+@.+..+/.test(fieldValues.username)
          ? ""
          : "Email is not valid.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      userService
        .forgotPassword(values)
        .then((response) => {
          setLoading(false);
          setNotify({
            isOpen: true,
            message: "We sent you an email to reset password",
            type: "success",
          });
          setTimeout(() => {
            // Add your logic for the transition
            navigate("/login");
          }, 1000);
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setLoading(false);
          setNotify({
            isOpen: true,
            message: "Something wrong. Please try again later",
            type: "error",
          });
        });
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Form onSubmit={handleSubmit}>
        <Paper elevation={10} className={classes.paper}>
          <Grid container justifyContent="center">
            <Grid item align="center" xs={12}>
              <Avatar sx={{ bgcolor: "#1bbd7e" }}>
                <SyncLockOutlinedIcon />
              </Avatar>
              <h2>Reset your password</h2>
              <MuiInput
                name="username"
                label="Your email address"
                value={values.username}
                onChange={handleInputChange}
                error={errors.username}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  mb: 1,
                  ml: 3,
                  gap: 1,
                }}
              >
              <LoadingButton
                type="submit"
                color="success"
                variant="contained"
                loading={loading}
              >
                  Continue
              </LoadingButton>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center", // Align typography and button vertically
                  }}
            >
              <Button variant="text" onClick={() => navigate(LOGIN_ROUTE)}>
                    Return to sign in
              </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </Container>
  );
}
