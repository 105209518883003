import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AppContext } from "../../../App";
import { MuiButton } from "../../../../../components";
import PageHeader from "../../../../../components/PageHeader";
import * as studentService from "../../../services/AssignmentStudentService";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../../../../../components/mui/Notification";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { DataGrid } from "@mui/x-data-grid";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "../../../../../components/ConfirmDialog";

export default function StudentAnswer() {
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState();
  const [notifying, setNotifying] = useState();
  const [records, setRecords] = useState([]);
  const [criteriaLoading, setCriteriaLoading] = useState();
  const [criteria, setCriteria] = useState({});
  const [editMode, setEditMode] = useState({});
  const [updatedGrade, setUpdatedGrade] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const firstName = state.firstName;
  const lastName = state.lastName;
  const caseStudyId = state.casestudyId;
  const assignmentId = state.assignmentId;
  const groupId = state.groupId;
  const groupName = state.groupName;
  const userId = appState.userInfo?.userId;
  const permittedUsers = process.env.REACT_APP_RE_EVALUATION_PEMITTED_USERS
    ? process.env.REACT_APP_RE_EVALUATION_PEMITTED_USERS.split(",")
    : [];

  const isUserPermitted = permittedUsers.includes(userId);

  useEffect(() => {
    studentService
      .getAnswers(groupId)
      .then((response) => {
        setRecords(response.data);
        response.data.forEach((element) => {
          loadCriteria(element.evaluationId);
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function questionIds() {
    return records.map((record) => record.questionId);
  }

  function criteriaIds(evalId) {
    return criteria[evalId].map((c) => c.id);
  }

  const reEvaluate = (record) => {
    const evalId = record.evaluationId;
    const request = {
      caseStudyId,
      assignmentId,
      groupId,
      evalId,
      questionIds: questionIds(),
      evalDetailIds: criteriaIds(evalId),
    };
    studentService
      .reEvaluate(request)
      .then(() => {
        setNotify({
          isOpen: true,
          message:
            "Re-evaluation in progress... Will be updated in couple of minutes!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      });
  };

  const loadCriteria = (evalId) => {
    setCriteriaLoading(true);
    studentService
      .getCriteria(evalId)
      .then((response) => {
        setCriteria((prevData) => ({
          ...prevData,
          [evalId]: response.data,
        }));
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setCriteriaLoading(false));
  };

  const submitGrade = (evalId) => {
    setSubmitting(true);
    studentService
      .updateGrade(evalId, updatedGrade[evalId])
      .then(() => {
        setEditMode((prevData) => ({
          ...prevData,
          [evalId]: false,
        }));
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setSubmitting(false));
  };

  const notifyApprovals = (id) => {
    setNotifying(true);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    studentService
      .notifyApproval(id)
      .then(() => {
        setNotify({
          isOpen: true,
          message: "Successfully sent a notification to student(s).",
          type: "success",
        });
        setTimeout(() => {
          // Add your logic for the transition
          navigate(-1, { replace: true });
        }, 2000);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not sent a notification. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setNotifying(false));
  };

  const handleConfirmation = () => {
    const recordsCount = records.length;
    const approvedCount = records.filter(
      (item) => item.evaluationStatus === "APPROVED"
    ).length;

    if (recordsCount !== approvedCount) {
      setNotify({
        isOpen: true,
        message:
          "Please approve all grades. " +
          (recordsCount - approvedCount) +
          " more approvals required.",
        type: "warning",
      });
    } else {
      setConfirmDialog({
        isOpen: true,
        title: "Are you sure you want to notify student(s)?",
        subTitle: "Email will be sent to them immediately.",
        onConfirm: () => {
          notifyApprovals(records[0]?.evaluationId);
        },
      });
    }
  };

  const approve = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    studentService
      .approve(id)
      .then(() => {
        setNotify({
          isOpen: true,
          message: "Approved Successfully",
          type: "success",
        });
        setRecords((prevRecords) =>
          prevRecords.map((record) =>
            record.evaluationId === id
              ? {
                  ...record,
                  evaluationStatus: "APPROVED",
                }
              : record
          )
        );
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not do the approval. Please try again later.",
          type: "error",
        });
      });
  };

  const handleApprove = (id, grade) => {
    setConfirmDialog({
      isOpen: true,
      title:
        "Are you sure to approve this answer with final Grade " + grade + "?",
      subTitle: "Email with final grade will be sent after confirmation",
      onConfirm: () => {
        approve(id);
      },
    });
  };

  const columns = [
    { field: "title", headerName: "Criteria Title", flex: 1, minWidth: 100 },
    {
      field: "grade",
      headerName: "Criteria Grade",
      flex: 1,
      minWidth: 100,
    },
  ];

  const isApproved = (status) => "APPROVED" === status;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title={
          groupName
            ? groupName + " 's Answer"
            : firstName + " " + lastName + " 's Answer"
        }
        isChild={true}
      />
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {records.map((record, rIndex) => (
              <Card
                key={rIndex}
                sx={{
                  mb: 2,
                  border: isApproved(record.evaluationStatus)
                    ? "solid green"
                    : "",
                }}
              >
                <CardHeader
                  title={<Typography variant="h5">{"Question"}</Typography>}
                  subheader={
                    <Typography variant="subtitle2">
                      {record.question}
                    </Typography>
                  }
                  action={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        border: "0px dashed white",
                        margin: 3,
                      }}
                    >
                      <ScoreboardIcon
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      />
                      <TextField
                        value={
                          updatedGrade[record.evaluationId] === -1
                            ? ""
                            : updatedGrade[record.evaluationId] || record.grade
                        }
                        id="grade"
                        label="Grade"
                        variant="standard"
                        disabled={!editMode[record.evaluationId]}
                        size="small"
                        type="number"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value) {
                            if (/^\d{1,3}$/.test(value)) {
                              // Check maxLength = 3
                              const numericValue = Number(value);
                              if (numericValue >= 0 && numericValue <= 100) {
                                setUpdatedGrade((prevData) => ({
                                  ...prevData,
                                  [record.evaluationId]: numericValue,
                                }));
                              }
                            }
                          } else {
                            setUpdatedGrade((prevData) => ({
                              ...prevData,
                              [record.evaluationId]: -1,
                            }));
                          }
                        }}
                        inputProps={{ min: 0, max: 100 }}
                        style={{ width: "60px" }}
                      />
                      {editMode[record.evaluationId] && (
                        <>
                          <LoadingButton
                            // sx={{ m: 1 }}
                            type="submit"
                            color="success"
                            variant="text"
                            size="small"
                            disabled={!updatedGrade[record.evaluationId]}
                            loading={submitting}
                            onClick={() => submitGrade(record.evaluationId)}
                          >
                            Submit
                          </LoadingButton>
                          <MuiButton
                            variant="text"
                            text="Cancel"
                            size="small"
                            onClick={() => {
                              setEditMode((prevData) => ({
                                ...prevData,
                                [record.evaluationId]: false,
                              }));
                              setUpdatedGrade((prevData) => ({
                                ...prevData,
                                [record.evaluationId]: record.grade,
                              }));
                            }}
                          />
                        </>
                      )}
                    </Box>
                  }
                />
                <CardContent>
                  <Typography variant="h6">{"Answer"}</Typography>
                  <Typography
                    variant="body1"
                    sx={{ ml: 2, mb: 2, color: "text.secondary" }}
                  >
                    {record.answer}
                  </Typography>
                  <Typography variant="h6">{"Evaluations"}</Typography>
                  {criteria[record.evaluationId] && (
                    <div style={{ width: "50%", marginLeft: 10 }}>
                      <DataGrid
                        sx={{
                          mb: 3,
                          "& .MuiDataGrid-row:first-of-type": {
                            // Fixing potential SSR issue with :first-child
                            // Apply any specific styles for the first row here if needed
                          },
                        }}
                        loading={criteriaLoading}
                        autoHeight
                        rows={criteria[record.evaluationId]}
                        columns={columns}
                        disableColumnResize
                        disableStrictModeCompat
                        density="compact"
                        hideFooter={true}
                      />
                    </div>
                  )}
                </CardContent>

                <CardActions>
                  {!isApproved(record.evaluationStatus) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box>
                        <Button
                          size="small"
                          color="warning"
                          onClick={() =>
                            setEditMode((prevData) => ({
                              ...prevData,
                              [record.evaluationId]: true,
                            }))
                          }
                        >
                          Edit Grade
                        </Button>
                        <Button
                          size="small"
                          color="success"
                          onClick={() =>
                            handleApprove(
                              record.evaluationId,
                              updatedGrade[record.evaluationId] || record.grade
                            )
                          }
                        >
                          Approve
                        </Button>
                      </Box>
                      <Box>
                        {/* <Button
                      size="small"
                      onClick={() => loadCriteria(record.evaluationId)}
                    >
                      Evaluation Details
                    </Button> */}
                        {isUserPermitted && (
                          <Button
                            size="small"
                            color="error"
                            onClick={() => reEvaluate(record)}
                          >
                            Re-Evaluate
                          </Button>
                        )}
                      </Box>
                    </Box>
                  )}
                </CardActions>
              </Card>
            ))}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                mt: 5,
              }}
            >
              <Stack spacing={2}>
                <LoadingButton
                  type="submit"
                  color="success"
                  variant="outlined"
                  loading={notifying}
                  onClick={() => handleConfirmation()}
                >
                  Notify student(s)
                </LoadingButton>
                <MuiButton
                  text={"Back"}
                  color="info"
                  variant="text"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Stack>
            </Box>
          </>
        )}
      </Paper>
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
