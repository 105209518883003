import React, { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Container, Tooltip } from "@mui/material";
import PageHeader from "../../../../../components/PageHeader";
import * as studentService from "../../../services/AssignmentStudentService";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../../../../../components/mui/Notification";
import { MuiButton } from "../../../../../components";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CASE_STUDY_ASSIGNMENT_STUDENT_ANSWER,
  CASE_STUDY_ASSIGNMENT_STUDENT_FEEDBACK,
} from "../../../helper/Constants";
import { upperCaseFirstLetter } from "../../../../../helper/TextHelper";
import ConfirmDialog from "../../../../../components/ConfirmDialog";

export default function List() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const assignment = state.assignment;
  const assignmentId = assignment.id;
  const casestudyId = state.casestudyId;
  const questionIds = state.assignment.questionIds;

  useEffect(() => {
    studentService
      .getAllForGroups(assignmentId, casestudyId, questionIds)
      .then((response) => {
        setStudents(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const handleViewAnswer = (groupId, groupName, firstName, lastName) => {
    if (groupId) {
      navigate(CASE_STUDY_ASSIGNMENT_STUDENT_ANSWER, {
        state: { casestudyId, assignmentId, groupId, groupName, firstName, lastName },
      });
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this student?",
      subTitle:
        "They will need to sign in again and re-enter the project code.",
      onConfirm: () => {
        deleteStudent(id);
      },
    });
  };

  const deleteStudent = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    studentService
      .deleteStudent(id)
      .then(() => {
        const newList = students.filter((item) => item.id !== id);
        setStudents(newList);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a user. Please try again later.",
          type: "error",
        });
      });
  };

  const handleViewFeedback = (
    groupId,
    feedbackReqIds,
    firstName,
    lastName,
    mode
  ) => {
    if (feedbackReqIds?.length > 0) {
      navigate(CASE_STUDY_ASSIGNMENT_STUDENT_FEEDBACK, {
        state: {
          groupId,
          feedbackReqIds,
          firstName,
          lastName,
          mode,
        },
      });
    }
  };

  const Student = ({ firstName, lastName }) => {
    return firstName + " " + lastName;
  };

  const Answer = ({
    answerIds,
    groupName,
    groupId,
    firstName,
    lastName,
    hasPendingEvaluation,
  }) => {
    return answerIds ? (
      <MuiButton
        size="small"
        text={hasPendingEvaluation ? "Submitted" : "Evaluated"}
        variant="text"
        onClick={() => handleViewAnswer(groupId, groupName, firstName, lastName)}
      />
    ) : (
      "Not Submitted"
    );
  };

  const Feedback = ({
    hasPendingFeedbackRequest,
    groupId,
    feedbackReqIds,
    firstName,
    lastName,
  }) => {
    return hasPendingFeedbackRequest ? (
      <MuiButton
        size="small"
        text={"Requested"}
        variant="text"
        onClick={() =>
          handleViewFeedback(
            groupId,
            feedbackReqIds,
            firstName,
            lastName,
            "edit"
          )
        }
      />
    ) : feedbackReqIds?.length > 0 ? (
      <MuiButton
        size="small"
        text={"Sent"}
        variant="text"
        onClick={() =>
          handleViewFeedback(
            groupId,
            feedbackReqIds,
            firstName,
            lastName,
            "view"
          )
        }
      />
    ) : (
      "Not Requested"
    );
  };

  const columns = [
    { field: "groupName", headerName: "Group", flex: 1, minWidth: 100 },
    {
      field: "firstName",
      headerName: "Student",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Student firstName={params.value} lastName={params.row.lastName} />
      ),
    },
    {
      field: "answerIds",
      headerName: "Answer",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <Answer
          answerIds={params.value}
          groupName={params.row.groupName}
          groupId={params.row.groupId}
          firstName={params.row.firstName}
          lastName={params.row.lastName}
          hasPendingEvaluation={params.row.hasPendingEvaluation}
        />
      ),
    },
    {
      field: "hasPendingFeedbackRequest",
      headerName: "Feedback",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <Feedback
          hasPendingFeedbackRequest={params.value}
          groupId={params.row.groupId}
          feedbackReqIds={params.row.feedbackReqIds}
          firstName={params.row.firstName}
          lastName={params.row.lastName}
        />
      ),
    },
    {
      field: "paymentId",
      headerName: "Payment",
      flex: 1,
      type: "boolean",
    },
    {
      field: "id",
      headerName: "Enrolled",
      flex: 1,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      getActions: (params) => [
        <Tooltip
          title={
            params.row.paymentId ? "Cannot delete, already paid" : "Delete"
          }
        >
          <span>
            <GridActionsCellItem
              disabled={params.row.paymentId}
              color="error"
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => onDelete(params.id)}
            />
          </span>
        </Tooltip>,
      ],
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader
        title={upperCaseFirstLetter(assignment.title) + " Students"}
        isChild={true}
        onRefresh={() => onRefresh()}
      />
      <DataGrid
        loading={loading}
        autoHeight
        // checkboxSelection
        rows={students}
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        // initialState={{
        //   pagination: { paginationModel: { pageSize: 20 } },
        // }}
        // pageSizeOptions={[10, 20, 50]}
        disableColumnResize
        density="compact"
        slotProps={{
          filterPanel: {
            filterFormProps: {
              logicOperatorInputProps: {
                variant: "outlined",
                size: "small",
              },
              columnInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              operatorInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
            },
          },
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
