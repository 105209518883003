import React, { useState } from "react";
import {
  Autocomplete,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  MuiInput,
  MuiButton,
  MuiDatePicker,
  TourStep,
  TourRide,
} from "../../../../components";
import { useForm, Form } from "../../../../components/mui/useForm";
import { useNavigate, useLocation } from "react-router-dom";
import Notification from "../../../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../../../components/PageHeader";
import * as courseService from "../../services/CourseService";
import { courseFields, validateCourse, formatDates } from "./core";
import { isEpmty } from "../../../../helper/TextHelper";
import TextbookAutoCompleteAsync from "./components/TextbookAutoCompleteAsync";

const studentLevelOptions = [
  "College",
  "Undergraduate",
  "MBA",
  "MBA-Executive",
  "Graduate",
  "Executive education-none degree",
];
const semesterOptions = ["Fall", "Summer", "Winter"];

export default function Upsert() {
  const { state } = useLocation();
  const [isInEditMode] = useState(state?.editMode || false);
  const navigate = useNavigate();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    validateCourse(values);
    let temp = { ...values.errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  function extractDefaultValues() {
    if (!isInEditMode) {
      const initialValues = {};
      courseFields.forEach((field) => {
        initialValues[field.name] = field.defaultValue;
      });
      return initialValues;
    } else {
      const course = state.course;
      return {
        ...course,
        ...(course.textBookId && {
          txtbook: {
            id: course.textBookId,
            title: course.textBook,
          },
        }),
      };
    }
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(extractDefaultValues(), false, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(values)) {
      upsert(values);
    }
  };

  const upsert = (obj) => {
    setLoading(true);
    formatDates(obj);
    obj["institutionId"] = state.institutionId;
    if (isEpmty(obj.id)) {
      courseService
        .create(obj)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not create a course. Please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            // Add your logic for the transition
            navigate(-1, { replace: true });
          }, 1000);
        });
    } else {
      courseService
        .update(obj)
        .then((response) => {
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not update a course. Please try again later.",
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            // Add your logic for the transition
            navigate(-1, { replace: true });
          }, 1000);
        });
    }
  };

  const onTextBookChange = (event, textbook) => {
    if (textbook) {
      setValues({ ...values, textBookId: textbook.id });
    } else {
      setValues({
        ...values,
        textBookId: "",
        txtbook: null,
      });
    }
  };

  const tourSteps = [
    {
      target: "#course-upsert-page-name",
      content: (
        <TourStep
          title={"Enter Course Name"}
          description={
            'Make sure to write the full title of your official course name without using any abbreviations. Example: "Strategic Management" or "Introduction to Marketing."'
          }
        />
      ),
    },
    {
      target: "#course-upsert-page-major",
      content: (
        <TourStep
          title={"Enter Major Name"}
          description={
            'The major should match the business field most closely related to the course. For example, the major for "Consumer Behavior" would be Marketing, while the major for "Strategic Management" could be Management.'
          }
        />
      ),
    },
    {
      target: "#course-upsert-page-code",
      content: (
        <TourStep
          title={"Enter Official Course Code"}
          description={
            'Add the official course code here, such as "MARK303" or "MGMT400". If your institution does not have a course code, enter the full course name instead.'
          }
        />
      ),
    },
    {
      target: "#course-upsert-page-section",
      content: (
        <TourStep
          title={"Add Course Section"}
          description={
            "If the course code includes a section, make sure to add it here."
          }
        />
      ),
    },
    {
      target: "#textbook-auto-complete-asynchronous",
      content: (
        <TourStep
          title={"Add Textbook"}
          description={
            "If your class uses a textbook, you can include it here. If not, you can skip this step."
          }
        />
      ),
    },
  ];

  return (
    <>
      {!isInEditMode && (
        <TourRide name="course-upsert-page" steps={tourSteps} />
      )}
      <Form onSubmit={handleSubmit}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <PageHeader
            title={isInEditMode ? values.name : "Add a New Course"}
            isChild={true}
          />
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <React.Fragment>
              <Typography sx={{ fontWeight: "bold" }} variant="h5" gutterBottom>
                Course
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MuiInput
                    // required
                    error={errors?.name}
                    id="course-upsert-page-name"
                    name="name"
                    label="Course Name"
                    value={values.name}
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                    helperText="Eg. Introduction to Marketing"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiInput
                    // required
                    error={errors?.field}
                    id="course-upsert-page-major"
                    name="field"
                    label="Major"
                    value={values.field}
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                    helperText="Eg. Marketing"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MuiInput
                    // required
                    error={errors.code}
                    id="course-upsert-page-code"
                    name="code"
                    label="Course Code"
                    value={values.code}
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                    helperText="Eg. Mark101"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiInput
                    // required
                    error={errors.section}
                    id="course-upsert-page-section"
                    name="section"
                    label="Section"
                    value={values.section}
                    fullWidth
                    variant="standard"
                    onChange={handleInputChange}
                    helperText="Eg. AA"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-semester"
                    value={values.semester}
                    options={semesterOptions}
                    onChange={(event, newValue) => {
                      setValues({ ...values, semester: newValue });
                    }}
                    renderInput={(params) => (
                      <MuiInput
                        {...params}
                        // required
                        error={errors.semester}
                        id="semester"
                        name="semester"
                        label="Semester"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-institution"
                  value={values.institution}
                  options={[{ label: "University" }, { label: "College" }]}
                  onChange={(event, newValue) => {
                    setValues({ ...values, institution: newValue.label });
                  }}
                  renderInput={(params) => (
                    <MuiInput
                      {...params}
                      required
                      error={errors.Institution}
                      id="institution"
                      name="institution"
                      label="Institution"
                      fullWidth
                    />
                  )}
                />
              </Grid> */}

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-st-level"
                    value={values.studentLevel}
                    options={studentLevelOptions}
                    onChange={(event, newValue) => {
                      setValues({ ...values, studentLevel: newValue });
                    }}
                    renderInput={(params) => (
                      <MuiInput
                        {...params}
                        // required
                        error={errors.studentLevel}
                        id="studentLevel"
                        name="studentLevel"
                        label="Program"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MuiDatePicker
                    // required
                    minDate={new Date()}
                    openTo="year"
                    views={["year", "month", "day"]}
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    value={values.startDate}
                    onChange={handleInputChange}
                    error={errors.startDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiDatePicker
                    // required
                    minDate={new Date()}
                    openTo="year"
                    views={["year", "month", "day"]}
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    value={values.endDate}
                    onChange={handleInputChange}
                    error={errors.endDate}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextbookAutoCompleteAsync
                    fullWidth
                    name="textBookId"
                    value={values.txtbook}
                    error={errors.textBookId}
                    label={"Text Book"}
                    getOptions={() => courseService.textbooks()}
                    onChange={onTextBookChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </Paper>

          <Grid item xs={4} sm={8} md={8} sx={{ mt: 2 }} align="center">
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction={{ xs: "column", sm: "row" }}
              sx={{ ml: 1 }}
            >
              <LoadingButton
                type="submit"
                color="success"
                variant="outlined"
                loading={loading}
              >
                Submit
              </LoadingButton>

              <MuiButton
                text="Reset"
                color="inherit"
                variant="outlined"
                onClick={resetForm}
              />

              <MuiButton
                text="Cancel"
                color="info"
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Stack>
          </Grid>
          <Notification notify={notify} setNotify={setNotify} />
        </Container>
      </Form>
    </>
  );
}
