import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import {
  MuiInput,
  MuiButton,
  TourRide,
  TourStep,
} from "../../../../components";
import { useForm, Form } from "../../../../components/mui/useForm";
import { useNavigate } from "react-router-dom";
import Notification from "../../../../components/mui/Notification";
import LoadingButton from "@mui/lab/LoadingButton";
import PageHeader from "../../../../components/PageHeader";
import * as institutionService from "../../services/InstitutionService";
import AutoCompleteAsync from "../../../../components/AutoCompleteAsync";
import { isEmailDomainValid } from "./functions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const initialFValues = {
  email: "",
  institutionId: "",
};

export default function New() {
  const navigate = useNavigate();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState();
  const [openNotFoundDialog, setOpenNotFoundDialog] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState();
  const [requestError, setRequestError] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseNotFoundDialog = () => {
    setOpenNotFoundDialog(false);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      temp.email =
        fieldValues.email !== "" && /$^|.+@.+..+/.test(fieldValues.email)
          ? isEmailDomainValid(fieldValues.email, domain)
            ? ""
            : domain
            ? "Email needs to end with @" + domain
            : "Please select institution"
          : "Email is not valid.";
    }
    if ("institutionId" in fieldValues) {
      temp.institution = fieldValues.institutionId
        ? ""
        : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      institutionService
        .create(values)
        .then((response) => {
          showNotificationAndGoBack();
        })
        .catch((error) => {
          console.error(`Error: ${error.message}`);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 409) {
              setNotify({
                isOpen: true,
                message: "Institution already exists.",
                type: "error",
              });
            } else {
              setNotify({
                isOpen: true,
                message:
                  "Could not add an institution. Please try again later.",
                type: "error",
              });
            }
          } else {
            setNotify({
              isOpen: true,
              message: "Something went wrong. Please try again later.",
              type: "error",
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const showNotificationAndGoBack = () => {
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
    setTimeout(() => {
      // Add your logic for the transition
      navigate(-1, { replace: true });
    }, 1000);
  };

  const onInstitutionChange = (event, institution) => {
    setValues({ ...values, institutionId: institution.id });
    validate({ institutionId: institution.id });
    setDomain(institution.domain);
  };

  const tourSteps = [
    {
      target: "#auto-complete-asynchronous",
      content: (
        <TourStep
          title={"Institution Name"}
          description={
            "Find your institution by start typing. If not found click on ADD NEW INSTITUTION"
          }
        />
      ),
    },
    {
      target: "#asyncautocomplete-notfound-icon",
      content: (
        <TourStep
          title={"Add your institution"}
          description={
            "If your institution is not listed add it by clikcing on this icon."
          }
        />
      ),
    },
    {
      target: "#institution-new-page-email",
      content: (
        <TourStep
          title={"Institution email address"}
          description={
            "Enter your institution email address, and wait for confirmation email."
          }
        />
      ),
    },
  ];

  return (
    <>
      <TourRide name="institution-new-page" steps={tourSteps} />
      <Form onSubmit={handleSubmit} fullWidth>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <PageHeader title={"Add a New Institution"} isChild={true} />
          <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
            <React.Fragment>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={7}>
                  <AutoCompleteAsync
                    noOptionsText={
                      <MuiButton
                        size="small"
                        text="Can't find your Institution?"
                        color="info"
                        variant="text"
                        onClick={() => {
                          setOpenNotFoundDialog(true);
                        }}
                      />
                    }
                    name="institution"
                    error={errors.institution}
                    label={"Institution"}
                    getOptions={() => institutionService.getAll()}
                    onChange={onInstitutionChange}
                    handleNotFound={() => setOpenNotFoundDialog(true)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiInput
                    id="institution-new-page-email"
                    name="email"
                    label="Your Institution email address"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </Paper>

          <Grid item xs={4} sm={8} md={8} align="center">
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction={{ xs: "column", sm: "row" }}
              sx={{ ml: 1 }}
            >
              <LoadingButton
                type="submit"
                color="success"
                variant="outlined"
                loading={loading}
              >
                Submit
              </LoadingButton>

              <MuiButton
                text="Reset"
                color="inherit"
                variant="outlined"
                onClick={resetForm}
              />

              <MuiButton
                text="Cancel"
                color="info"
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Stack>
          </Grid>
          <Notification notify={notify} setNotify={setNotify} />
        </Container>
      </Form>
      <Dialog
        fullScreen={fullScreen}
        open={openNotFoundDialog}
        onClose={handleCloseNotFoundDialog}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const institution = formJson.institution?.trim();
            if (!institution) {
              setRequestError("Institution name cannot be empty.");
              return;
            }
            setLoadingRequest(true);
            institutionService
              .request(institution)
              .then((res) =>
                setNotify({
                  isOpen: true,
                  message:
                    "Successfully submitted. We will update the list and send you an email.",
                  type: "success",
                })
              )
              .catch((e) => {
                console.error(`Error: ${e.message}`);
              })
              .finally(() => {
                setLoadingRequest(false);
                handleCloseNotFoundDialog();
              });
          },
        }}
      >
        <DialogTitle>Help us add your institution</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of your institution. We will update the list
            and notify you once it's added.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="institution"
            name="institution"
            label="Institution name"
            fullWidth
            variant="standard"
            error={Boolean(requestError)}
            helperText={requestError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotFoundDialog}>Cancel</Button>
          <LoadingButton loading={loadingRequest} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
