import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { AnimatedCard } from "./AnimatedCard";
import Notification from "../../../../components/mui/Notification";
import * as casestudyService from "../../services/CasestudyService";
import { isEpmty } from "../../../../helper/TextHelper";
import {
  Assistant,
  ChipsEditMessage,
  InfoTooltip,
  TourRide,
  TourStep,
} from "../../../../components";

const CaseTopics = ({ course, formValues, updateValues }) => {
  const topicsValues = formValues.topics;
  const companyValues = formValues.company;
  const baseInfoValues = formValues.baseInfo;
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const caseStudyId = formValues.caseStudyId;

  const getTopics = () => {
    setTopicsLoading(true);
    const request = {
      caseStudyId: caseStudyId,
      field: course.field,
      chapterTitle: baseInfoValues.chapters[0],
      chapterTopics: baseInfoValues.chapterTopics?.join(", "),
      companyName: companyValues.names[0],
      studentLevel: course.studentLevel,
      marketTerritory: companyValues.territory,
      marketAnalysisLevel: companyValues.marketAnalysisLevel,
    };
    casestudyService
      .getTopics(request)
      .then((res) => {
        updateValues("topicsCards", res.data);
        updateValues("topicsHiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch topics. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTopicsLoading(false);
      });
  };

  const handleTopicsChips = (newChips) => {
    if (newChips.length > 0) {
      const newChip = newChips[newChips.length - 1];
      updateValues("list", [newChip]);
      if (topicsValues.topicsCards?.some((card) => card.title !== newChip)) {
        updateValues("topicsHiddenCards", topicsValues.topicsCards);
      }
    }
  };

  const handleTopicsCardClick = (card) => {
    updateValues("list", [card.title]);

    const filteredCards = topicsValues.topicsCards.filter(
      (crd) => crd.title !== card.title
    );
    updateValues("topicsHiddenCards", filteredCards);

    if (topicsValues.errors) {
      const targetObject = Object.assign({}, topicsValues.errors);
      targetObject.list = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleDeleteTopicsChip = (chipValue, chipIndex) => {
    updateValues("list", []);

    // Check if chipValue exists in chaptersCards and not already in hiddenCards
    const existingCard = topicsValues.topicsCards.find(
      (card) => card.title === chipValue
    );
    const isCardHidden = topicsValues.topicsHiddenCards.some(
      (hiddenCard) => hiddenCard.title === chipValue
    );

    if (existingCard && !isCardHidden) {
      topicsValues.topicsHiddenCards.push(existingCard);
    }
  };

  const tourSteps = [
    {
      target: "#topic-page-step-1",
      content: (
        <TourStep
          title={"Add Main Topic"}
          description={
            "Add only ONE main topic. It is the main challenge of the case study and defines all elements in the case."
          }
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <TourRide name="case-study-creation-topic-page" steps={tourSteps} />
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Important</AlertTitle>
        In this step, add the main topic of the case. This is one of the most
        important steps in case creating process.
      </Alert>
      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item position={"relative"}>
            <Typography
              id="topic-page-step-1"
              sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
              variant="h6"
              gutterBottom
            >
              Main Topic
            </Typography>
            <InfoTooltip
              title={
                "The main topic is the foundation of your case study. It defines the central challenge and shapes all elements in the case. You can select only ONE main topic."
              }
            />
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <Assistant
              disabled={topicsValues.topicsCards?.length > 0}
              onClick={getTopics}
              loading={topicsLoading}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              hideClearAll
              addOnBlur
              value={topicsValues.list}
              onDeleteChip={handleDeleteTopicsChip}
              onChange={handleTopicsChips}
              error={
                isEpmty(topicsValues.list) && Boolean(topicsValues.errors?.list)
              }
              helperText={
                topicsValues.errors?.list ? (
                  topicsValues.errors?.list
                ) : isEpmty(topicsValues.list) ? (
                  ""
                ) : (
                  <ChipsEditMessage />
                )
              }
              placeholder="Use Assistant or enter main topic"
            />
          </Grid>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {topicsValues.topicsHiddenCards?.map((card, index) => (
              <AnimatedCard
                key={index}
                card={card}
                index={index}
                handleCardClick={handleTopicsCardClick}
              />
            ))}
          </div>
        </Grid>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </Container>
  );
};

export default CaseTopics;
