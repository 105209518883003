import React, { useState, useEffect, useContext } from "react";
import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as courseService from "../../services/CourseService";
import CourseCard from "./components/CourseCard";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Notification from "../../../../components/mui/Notification";
import NewCard from "../../../../components/NewCard";
import PageHeader from "../../../../components/PageHeader";
import {
  CASE_STUDY_LIST,
  INSTRUCTOR_COURSE_UPSERT_ROUTE,
} from "../../helper/Constants";
import { AppContext } from "../../App";

export default function List() {
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const institutionId = state.institutionId;

  useEffect(() => {
    // if (!isEpmty(state)) {
    // const eventId = state.eventId;
    // setEventId(eventId);
    courseService
      .getAll(institutionId)
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
    // } else setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    courseService
      .del(id)
      .then(() => {
        const newCourses = courses.filter((item) => item.id !== id);
        setCourses(newCourses);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a course. Please try again later.",
          type: "error",
        });
      });
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        onDelete(id);
      },
    });
  };

  const handleCasestudy = (course) => {
    navigate(CASE_STUDY_LIST, { state: { course, institutionId } });
  };

  const handleEdit = (course) => {
    navigate(INSTRUCTOR_COURSE_UPSERT_ROUTE, {
      state: { course, editMode: true, institutionId },
    });
  };

  const handleNew = () => {
    if (appState.isLoggedIn && !appState.userInfo?.hasPromptPermission) {
      setNotify({
        isOpen: true,
        message:
          "No more credits avaiable. Please contact us to get unblocked!",
        type: "error",
      });
    } else {
      navigate(INSTRUCTOR_COURSE_UPSERT_ROUTE, {
        state: { institutionId },
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <PageHeader title="List of Courses" isChild={true} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <NewCard title={"New Course"} onclick={handleNew} />
        </Grid>
        {loading
          ? Array.from(new Array(3)).map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ mt: 2 }}
              >
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : courses.map((course, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <CourseCard
                  course={course}
                  handleEdit={() => handleEdit(course)}
                  handleDelete={handleDelete}
                  handleCasestudy={() => handleCasestudy(course)}
                />
              </Grid>
            ))}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
