import React, { useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Alert,
  AlertTitle,
} from "@mui/material";
import * as courseService from "../../../services/CourseService";
import { isEpmty } from "../../../../../helper/TextHelper";
import { MuiChipsInput } from "mui-chips-input";
import { TextBookChaptersAnimatedCard } from "../TextBookChaptersAnimatedCard";
import Notification from "../../../../../components/mui/Notification";
import { TextBookChapterTopicCard } from "../TextBookChapterTopicCard";
import {
  Assistant,
  ChipsEditMessage,
  InfoTooltip,
  TourRide,
  TourStep,
} from "../../../../../components";

export const Page = ({ course, formValues, onInputChange, updateValues }) => {
  const baseInfoValues = formValues.baseInfo;
  const [chaptersLoading, setChaptersLoading] = useState();
  const [currentChapter, setCurrentChapter] = useState();
  const [topicsLoading, setTopicsLoading] = useState();
  const [topicsMessage, setTopicsMessage] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const hasTextBook = course.textBookId ? true : false;

  const getTextbookChapters = () => {
    setChaptersLoading(true);
    courseService
      .getTextbookChapters(course.textBookId)
      .then((res) => {
        updateValues("chaptersCards", res.data);
        updateValues("chaptersHiddenCards", res.data);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message: "Could not fetch textbook chapters. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setChaptersLoading(false);
      });
  };

  const getTextbookChapterTopics = () => {
    setTopicsLoading(true);
    courseService
      .getTextbookChapterTopics(
        currentChapter.title,
        course.name,
        course.field,
        course.textBook
      )
      .then((res) => {
        setTopicsMessage(res.data.message);
        updateValues("chapterTopicsCards", res.data.topics);
        updateValues("chapterTopicsHiddenCards", res.data.topics);
      })
      .catch((e) => {
        console.error(e);
        setNotify({
          isOpen: true,
          message:
            "Could not fetch textbook chapter topics. Please try again later",
          type: "error",
        });
      })
      .finally(() => {
        setTopicsLoading(false);
      });
  };

  const handleChapterChipsChange = (newChips) => {
    if (newChips.length > 0) {
      const newChip = newChips[newChips.length - 1];
      setCurrentChapter({ id: 1000, title: newChip });
      updateValues("chapters", [newChip]);
      updateValues("chapterTopics", []);
      updateValues("chapterTopicsCards", []);
      updateValues("chapterTopicsHiddenCards", []);

      if (
        baseInfoValues.chaptersCards?.some((card) => card.title !== newChip)
      ) {
        updateValues("chaptersHiddenCards", baseInfoValues.chaptersCards);
      }
    }
  };

  const handleChapterTopicsChipsChange = (newChips) => {
    updateValues("chapterTopics", newChips);
  };

  const handleChaptersCardClick = (card) => {
    const filteredCards = baseInfoValues.chaptersCards.filter(
      (crd) => crd.id !== card.id
    );
    setCurrentChapter(card);
    updateValues("chapters", [card.title]);
    updateValues("chaptersHiddenCards", filteredCards);
    updateValues("chapterTopics", []);
    updateValues("chapterTopicsCards", []);
    updateValues("chapterTopicsHiddenCards", []);

    if (baseInfoValues.errors) {
      const targetObject = Object.assign({}, baseInfoValues.errors);
      targetObject.chapters = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleChapterTopicsCardClick = (card) => {
    const filteredCards = baseInfoValues.chapterTopicsHiddenCards.filter(
      (crd) => crd !== card
    );
    updateValues("chapterTopics", [...baseInfoValues.chapterTopics, card]);
    updateValues("chapterTopicsHiddenCards", filteredCards);

    if (baseInfoValues.errors) {
      const targetObject = Object.assign({}, baseInfoValues.errors);
      targetObject.chapterTopics = undefined;
      updateValues("errors", targetObject);
    }
  };

  const handleDeleteChaptersChip = (chipValue, chipIndex) => {
    setCurrentChapter(undefined);
    setTopicsMessage(undefined);
    updateValues("chapters", []);
    updateValues("chapterTopics", []);
    updateValues("chapterTopicsCards", []);
    updateValues("chapterTopicsHiddenCards", []);

    // Check if chipValue exists in chaptersCards and not already in hiddenCards
    const existingCard = baseInfoValues.chaptersCards?.find(
      (card) => card.title === chipValue
    );
    const isCardHidden = baseInfoValues.chaptersHiddenCards?.some(
      (hiddenCard) => hiddenCard.title === chipValue
    );

    if (existingCard && !isCardHidden) {
      baseInfoValues.chaptersHiddenCards.push(existingCard);
    }
  };

  const handleDeleteChapterTopicsChip = (chipValue, chipIndex) => {
    const filteredChips = baseInfoValues.chapterTopics.filter(
      (chp) => chp !== chipValue
    );
    updateValues("chapterTopics", filteredChips);

    // Check if chipValue exists in chapterTopicsCards and not already in chapterTopicsHiddenCards
    const existingCard = baseInfoValues.chapterTopicsCards.find(
      (card) => card === chipValue
    );
    const isCardHidden =
      baseInfoValues.chapterTopicsHiddenCards.includes(chipValue);

    if (existingCard && !isCardHidden) {
      baseInfoValues.chapterTopicsHiddenCards.push(existingCard);
    }
  };

  const tourSteps = [
    {
      target: "#info-page-step-1",
      content: (
        <TourStep
          title={"Notice"}
          description={
            "Do not use the browser’s back button, as doing so will remove all your progress in creating a case study, and you’ll have to start over."
          }
        />
      ),
    },
    {
      target: "#info-page-step-2",
      content: (
        <TourStep
          title={hasTextBook ? "Add Chapter Title" : "Enter Main Theme of Case"}
          description={
            hasTextBook
              ? "Add chapter title here. Use BCL assistant or Enter manually. You can only add ONE chapter."
              : "The main theme serves as the foundation of your case study. Choose a broad theme, as you will break it into smaller parts later. You can only add ONE theme. Examples: new product development."
          }
        />
      ),
    },
    {
      target: "#info-page-step-3",
      content: (
        <TourStep
          title={"Enter Main Concepts"}
          description={
            hasTextBook
              ? "Add Concepts closely related to selected chapter. Use BCL Assistant or Enter concept(s) related to the selected chapter."
              : "Add Concepts closely related to your main theme. Use BCL Assistant or enter concept(s) related to the main theme"
          }
        />
      ),
    },
    {
      target: "#bcl-assistant",
      content: (
        <TourStep
          title={"BCL Assistance"}
          description={
            "Use the Assistant button during the case study process or enter your own content."
          }
        />
      ),
    },
  ];

  const mainTitle = () => (hasTextBook ? "Textbook Chapter" : "Main Theme");
  const mainTitleInfo = hasTextBook
    ? "You can select only one chapter from your textbook. Use BCL Assistant or you can enter chapter title."
    : "The main theme serves as the foundation of your case study. Examples of main themes include: consumer purchase behavior during a recession, new product development, regulatory and political environment, market and consumer research, or mergers and acquisitions. Choose a broad theme, as you will break it into smaller parts later. Add only ONE theme.";
  const mainPlaceholder = () =>
    hasTextBook
      ? "Use Assistant or Enter a chapter title."
      : "Enter main theme of the case study";
  const mainConceptTitleInfo = hasTextBook
    ? "Use BCL Assistant or enter concepts related to selected chapter. For example, for marketing strategy chapter, key concepts might include segmentation, targeting, and positioning. Depending on your case, the concepts may vary. Keep the number of concepts limited to maintain a focused and effective case study."
    : "Concepts are closely related to your main theme. For example, in a marketing strategy theme, key concepts might include segmentation, targeting, and positioning. Depending on your case, the concepts may vary. Keep the number of concepts limited to maintain a focused and effective case study.";

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <TourRide name="case-study-creation-info-page" steps={tourSteps} />
      <Alert id="info-page-step-1" severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Important</AlertTitle>
        Please avoid using the browser's back button, as it will reset your
        progress, requiring you to start over.
      </Alert>
      <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item sx={{ position: "relative" }}>
            <Typography
              sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
              variant="h6"
              gutterBottom
              id="info-page-step-2"
            >
              {mainTitle()}
            </Typography>
            <InfoTooltip title={mainTitleInfo} />
          </Grid>
          {hasTextBook && (
            <Grid item style={{ marginLeft: "auto" }}>
              <Assistant
                disabled={baseInfoValues.chaptersCards?.length > 0}
                onClick={getTextbookChapters}
                loading={chaptersLoading}
              />
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              style={{ width: "95%" }}
              hideClearAll
              addOnBlur
              value={
                isEpmty(baseInfoValues.chapters)
                  ? []
                  : [baseInfoValues.chapters[0]]
              }
              onDeleteChip={handleDeleteChaptersChip}
              onChange={handleChapterChipsChange}
              error={
                isEpmty(baseInfoValues.chapters) &&
                Boolean(baseInfoValues.errors?.chapters)
              }
              // InputProps={{
              //   readOnly: true,
              // }}
              helperText={
                isEpmty(baseInfoValues.chapters) ? "" : <ChipsEditMessage />
              }
              placeholder={mainPlaceholder()}
            />
          </Grid>
        </Grid>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {baseInfoValues.chaptersHiddenCards?.map((card, index) => (
            <TextBookChaptersAnimatedCard
              key={index}
              card={card}
              index={index}
              handleCardClick={handleChaptersCardClick}
            />
          ))}
        </div>
      </Paper>

      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        {topicsMessage && (
          <Alert severity="info" sx={{ mb: 2 }}>
            {topicsMessage}
          </Alert>
        )}
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Grid item position={"relative"}>
            <Typography
              id="info-page-step-3"
              sx={{ fontWeight: "bold", pr: 4 }} // Add right padding so text doesn't overlap with the icon
              variant="h6"
              gutterBottom
            >
              Main Concepts
            </Typography>
            <InfoTooltip title={mainConceptTitleInfo} />
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Assistant
              disabled={
                isEpmty(baseInfoValues.chapters) ||
                baseInfoValues.chapterTopicsCards?.length > 0
              }
              onClick={getTextbookChapterTopics}
              loading={topicsLoading}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <MuiChipsInput
              disabled={isEpmty(baseInfoValues.chapters)}
              style={{ width: "95%" }}
              hideClearAll
              addOnBlur
              value={baseInfoValues.chapterTopics}
              onDeleteChip={handleDeleteChapterTopicsChip}
              onChange={handleChapterTopicsChipsChange}
              error={
                isEpmty(baseInfoValues.chapterTopics) &&
                Boolean(baseInfoValues.errors?.chapterTopics)
              }
              helperText={
                isEpmty(baseInfoValues.chapterTopics) ? (
                  ""
                ) : (
                  <ChipsEditMessage />
                )
              }
              placeholder="Use Assistant or Enter concept(s) from selected item above"
            />
          </Grid>
        </Grid>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {baseInfoValues.chapterTopicsHiddenCards?.map((card, index) => (
            <TextBookChapterTopicCard
              key={index}
              card={card}
              index={index}
              handleCardClick={handleChapterTopicsCardClick}
            />
          ))}
        </div>
      </Paper>

      {/* <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <React.Fragment>
          <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
            Date
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MuiDatePicker
                minDate={new Date()}
                openTo="year"
                views={["year", "month", "day"]}
                name="startDate"
                label="Start Date"
                value={baseInfoValues.startDate}
                onChange={(e) => onInputChange(e)}
                error={baseInfoValues.errors?.startDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiDatePicker
                minDate={new Date()}
                openTo="year"
                views={["year", "month", "day"]}
                name="dueDate"
                label="Due Date"
                value={baseInfoValues.dueDate}
                onChange={(e) => onInputChange(e)}
                error={baseInfoValues.errors?.dueDate}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper> */}

      <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
        <React.Fragment>
          <Typography sx={{ fontWeight: "bold" }} variant="h5" gutterBottom>
            Market Type
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                required
                error={!isEpmty(baseInfoValues.errors?.marketType)}
              >
                <RadioGroup
                  aria-labelledby="market-type-radio-buttons-group"
                  // defaultValue="biz-market"
                  name="marketType"
                  value={baseInfoValues.marketType}
                  onChange={(e) => onInputChange(e)}
                >
                  <FormControlLabel
                    value="b2b"
                    control={<Radio />}
                    label="B2B Market"
                  />
                  <FormControlLabel
                    value="b2c"
                    control={<Radio />}
                    label="B2C Market"
                  />
                </RadioGroup>
                <FormHelperText>
                  {baseInfoValues.errors?.marketType}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </React.Fragment>
      </Paper>

      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </Container>
  );
};
