import { httpClient } from "./RestService";

export const getAll = async (assignmentId, casestudyId, questionIds) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/student/" +
      assignmentId +
      "/list/" +
      casestudyId +
      "?questionIds=" +
      questionIds
  );
};

export const getAllForGroups = async (
  assignmentId,
  casestudyId,
  questionIds
) => {
  return await httpClient().get(
    "/v1/education/cs/" +
      casestudyId +
      "/assmnt/" +
      assignmentId +
      "/gr/students?questionIds=" +
      questionIds
  );
};

export const getAnswers = async (groupId) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/student/answer/" + groupId
  );
};

export const getFeedbacks = async (groupId, feedbackRequestIds, mode) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/student/feedback/" +
      groupId +
      "?feedbackRequestIds=" +
      feedbackRequestIds +
      "&mode=" +
      mode
  );
};

export const deleteFeedback = async (id) => {
  return await httpClient().delete(
    "/v1/education/cs/assmnt/student/feedback/" + id
  );
};

export const sendFeedback = async (groupId, request) => {
  return await httpClient().post(
    "/v1/education/cs/assmnt/student/feedback/confirm/" + groupId,
    { records: request }
  );
};

export const getCriteria = async (evalId) => {
  return await httpClient().get(
    "/v1/education/cs/assmnt/student/criteria/" + evalId
  );
};

export const updateGrade = async (evalId, body) => {
  return await httpClient().put(
    "/v1/education/cs/assmnt/student/eval/" + evalId,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const approve = async (evalId) => {
  return await httpClient().put(
    "/v1/education/cs/assmnt/student/eval/approve/" + evalId
  );
};

export const notifyApproval = async (evalId) => {
  return await httpClient().put(
    "/v1/education/cs/assmnt/student/eval/approve/notify/" + evalId
  );
};

export const reEvaluate = async (request) => {
  return await httpClient().post(
    "/v1/education/cs/assmnt/student/eval/retry",
    request
  );
};

export const deleteStudent = async (id) => {
  return await httpClient().delete(
    "/v1/education/cs/assmnt/student/cstd/" + id
  );
};