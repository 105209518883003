import React from "react";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Avatar,
  Box,
  CardContent,
  Grid,
  Typography,
  Card,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Link,
  CardActions,
  Tooltip,
} from "@mui/material";
import { MuiButton, MuiInput } from "../../../../../components";
import { useTheme } from "@mui/material/styles";
import { isEpmty } from "../../../../../helper/TextHelper";
import { isEmailDomainValid } from "../functions";

export default function UserInstitutionCard({
  userInstitution,
  resendEmail,
  handleDelete,
  handleCourseList,
}) {
  const [open, setOpen] = React.useState(false);
  const [emailValue, setEmailValue] = React.useState(userInstitution.email);
  const [emailError, setEmailError] = React.useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getStatusColor = (status) => {
    switch (status) {
      case "WAITING_FOR_EMAIL_CONFIRMATION":
        return "#ff9800"; // Orange '#4caf50'; // Green '#f44336'; // Red  '#2196f3'; // Blue
      default:
        return "info";
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmailValue(value);
    if (isEmailDomainValid(value, userInstitution.domain)) {
      setEmailError("");
    } else setEmailError("Must end with @" + userInstitution.domain);
  };

  return (
    <Card
      elevation={1}
      sx={{
        m: 0.5,
        border:
          userInstitution.status === "WAITING_FOR_EMAIL_CONFIRMATION"
            ? "1px solid"
            : undefined,
        borderColor: getStatusColor(userInstitution.status),
      }}
    >
      <CardHeader
        avatar={
          <Avatar>{userInstitution.type?.toUpperCase().charAt(0)}</Avatar>
        }
        action={
          <Tooltip
            title={
              userInstitution.numberOfCourses > 0
                ? "Cannot delete, courses exists"
                : "Delete"
            }
          >
            <span>
              <IconButton
                color="secondary"
                onClick={handleDelete}
                disabled={userInstitution.numberOfCourses > 0}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        }
        title={userInstitution.name}
        subheader={
          userInstitution.domain && (
            <Link
              href={userInstitution.website}
              target="_blank"
              rel="noopener noreferrer"
              component="a"
            >
              {userInstitution.domain}
            </Link>
          )
        }
      />

      <a
        href={userInstitution.website}
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <CardMedia
          component="img"
          height="194"
          image={userInstitution.logo_url || "/resources/upload.png"}
          alt="logo"
        /> */}
      </a>
      {userInstitution.status === "WAITING_FOR_EMAIL_CONFIRMATION" ? (
        <CardContent id="user-institution-card-waiting">
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary" align="center">
                {"Please check your email " + userInstitution.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <MuiButton
                  id="user-institution-card-resend"
                  variant="text"
                  size="small"
                  text="Wrong email/Didn't receive?"
                  onClick={handleClickOpen}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardActions>
          <MuiButton
            id="user-institution-card-courses"
            size="small"
            text={"Courses(" + userInstitution.numberOfCourses + ")"}
            variant="text"
            sx={{ ml: 1 }}
            onClick={handleCourseList}
          />
        </CardActions>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            if (isEpmty(emailError)) {
              handleClose();
              resendEmail(email);
            }
          },
        }}
      >
        <DialogTitle>{"Didn't receive a verification email?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please double check your email address below and Submit!
          </DialogContentText>
          <MuiInput
            margin="dense"
            required
            fullWidth
            variant="standard"
            name="email"
            type="email"
            autoFocus
            value={emailValue}
            onChange={handleEmailChange}
            error={emailError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={!isEpmty(emailError)}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
