import React, { useEffect, useState } from "react";
import { MuiButton } from "../../../../../components";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";

const TextbookAutoCompleteAsync = ({
  value,
  name,
  label,
  getOptions,
  onChange,
  error = null,
  noOptionsText = "No Options",
  handleNewBtn,
  showNewBtn = false,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    getOptions()
      .then((response) => {
        if (active) {
          const newBtnOption = {
            title: name + " Not Found?",
            isSpecial: true,
          };
          if (showNewBtn && handleNewBtn) {
            setOptions([newBtnOption, ...response.data]);
          } else {
            setOptions(response.data);
          }
        }
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
      });

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [loading]);

  return (
    <Autocomplete
      id="textbook-auto-complete-asynchronous"
      noOptionsText={noOptionsText}
      name={name}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        if (option.isSpecial) {
          return (
            <li {...props} key="special-option">
              <MuiButton
                size="small"
                text={"Add new " + name}
                color="info"
                variant="text"
                onClick={() => {
                  handleNewBtn();
                }}
              />
            </li>
          );
        }
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item sx={{ display: "flex", width: 44 }}>
                <MenuBookIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Box component="span" sx={{ fontWeight: "regular" }}>
                  {option.title}
                </Box>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {"By: " + option.authors?.map(String).join(", ")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {"Edition: " + option.edition}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...other}
          {...(error && { error: true, helperText: error })}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default TextbookAutoCompleteAsync;
